import { AfterViewInit, Component, ViewChild, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ComponentFactoryResolver, Injector, OnDestroy, SimpleChange, ViewContainerRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject, of, toArray } from 'rxjs';
import { merge, Observable, of as observableOf, pipe } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { FormControl } from '@angular/forms';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSelectChange } from '@angular/material/select';

import { VisitorService, Visitor, VisitorTable, VisitorStats } from '../services/visitor.service';
import { ExhibitorService, Exhibitor, ExhibitorTable } from '../services/exhibitor.service';

import { PhutureIavatarComponent } from '../@components/phuture-iavatar/phuture-iavatar.component';

import { environment } from '../../environments/environment';

import { Logger } from '@core';

import { AuthQuery } from '@app/auth/auth.query';

import { JournalService, MailService, Mail } from '@core';

export interface EmpFilter {
  name: string;
  options: string[];
  defaultValue: string;
}

export interface filterOption {
  name: string;
  value: string;
  isdefault: boolean;
}

@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss', '../../assets/dk/css/libs.bundle.css', '../../assets/dk/css/theme.bundle.css'],
})
export class DashboardComponent implements OnInit {
  // content values
  _txt_subline = environment.txt_Subline;
  _txt_event_domain = environment.txt_Event_Domain;

  //
  //

  displayedColumns: string[] = [
    't_visitorid',

    'zusatz1', // status

    'profile_image',
    'country',

    'company',

    'decharge_resp_civile',
    'estimate',

    'title',
    'lastname',
    'firstname',
    'position',
    'adress',
    'areacode',
    'city',
    'companyphone',
    'emailincharge',
    'companymail',
    'icphone',
    'website',

    /*
    'generalisttour',
    'specialisttour',
    'tourownretailing',
    'tourretailingtravelagencies',
    'touroperatormember',
    'travel',
    'incentive',
    'inernationalincoming',
    'coach',
    'carrental',
    'hotelbookingcallcenter',
    'aircarrier',
    'seacarrier',
    'roadcarrier',
    'railcarrier',
    'numberemployees',
    'volumegroups',
    'volumeindividuals',
    'numberbrochures',
    'onlinebooking',

    'palsace',
    'pauvergne',
    'pbrittany',
    'pburgundy',
    'pcorsica',
    'pchampagne',
    'ploirevalley',
    'pnordpasdecalais',
    'pnormandy',
    'plimousin',
    'pfranche',
    'plancuedoc',
    'poverseas',
    'pparis',
    'pwesternloire',
    'pprovence',
    'pall',
    'pobusiness',
    'pobeach',
    'pocruises',
    'pobarging',
    'pogolf',
    'poyouth',
    'posummer',
    'ponature',
    'poreligious',
    'powinter',
    'pocultural',
    'pogastronomy',
    'pourban',
    'poactive',
    'pochampagne',
    'pofitness',
    'sauvergne',
    'sbrittany',
    'sburgundy',
    'scorsica',
    'schampagne',
    'sloire',
    'snordpasdecalais',
    'snormandy',
    'slimousin',
    'sfranche',
    'slanguedoc',
    'soverseas',
    'sparis',
    'swesternloire',
    'sprovence',
    'sall',
    'khotelgroup',
    'k2starhotel',
    'k3starhotel',
    'k4starhotel',
    'palacehotels',
    'kvacation',
    'kholidayclub',
    'kbandb',
    'krestaurant',
    'kincomingagency',
    'kcongress',
    'gsightseeing',
    'kairtrain',
    'kcoach',
    'kbarging',
    'kleisure',
    'kmuseum',
    'kshow',
    'kmall',
    'ktouristoffice',
    'danydietryallergies',
    */

    /*

    'storno_accepted',
    'dsgvo_accepted',
    'agb_accepted',
    */

    'chkcity1',
    'chkcity2',

    'garage_day1',
    'garage_day2',

    'lunch_day1',
    'lunch_day2',

    'attendance_event',
    'hotel_stay_night',
    'transfer_arrival',
    'transfer_return',

    'data1', // fransfer_from
    'flight_booked',
    'data2', // transfer_type

    'special_wishes',

    'actions',
  ];

  // environment
  imageStorage = environment.eplusSystem + '/_fileDB/images/visitor/';

  _eventYear = environment.event_year;
  _veranstalter = environment.veranstalter;
  _hotel_info = environment.hotel_info;
  _event_space = environment.event_space;
  _event_date = environment.event_date;
  _book_night = environment.book_night;
  _cancel_date = environment.cancel_date;
  // ---

  visitorTable: VisitorTable;

  totalItems: number;
  //visitors: Visitor[];
  visitors$: Observable<Visitor[]>;

  stats: VisitorStats;
  /*
  stat_nbr_visitors: Observable<number>;
  stat_nbr_exhibitors: Observable<number>;
  stat_nbr_appointments: Observable<number>;
  stat_nbr_registered: Observable<number>;
  stat_nbr_approved: Observable<number>;
  stat_nbr_fs_done: Observable<number>;
  */

  dataSource = new MatTableDataSource<Visitor>();

  isLoading = true;

  searchTerm$ = new BehaviorSubject<string>('');

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  pageSizes = [5, 10, 20, 50, 100, 200, 500, this.dataSource.data.length];
  initPageSize = 100;

  searchValue: string;
  //searchKeywordFilter = new FormControl();

  countries: string[] = [
    'All',
    'Austria',
    'Bosnia-Herzegovina',
    'Bulgaria',
    'Croatia',
    'Czech Republic',
    'France',
    'Germany',
    'Hungary',
    'Kosovo',
    'Macedonia',
    'Montenegro',
    'Poland',
    'Romania',
    'Serbia',
    'Slovakia',
    'Slovenia',
    'Switzerland',
  ];

  stati: string[] = ['All', 'Register New', 'Approved', 'First-Start Done', 'Last Year'];
  days: string[] = ['All', 'Day 1 - ' + environment.day_1, 'Day 2 - ' + environment.day_2];
  empFilters: EmpFilter[] = [];
  defaultValue = 'All';
  filterDictionary = new Map<string, string>();
  //dataSourceFilters = new MatTableDataSource();
  filters$ = new BehaviorSubject<string>('');

  // dilaog und sidenav
  openSidebar = false;
  @ViewChild('sidenav') sidenav;
  selectedVisitor: Visitor;
  @ViewChild('visitorContainer', { read: ViewContainerRef }) quizContainer: ViewContainerRef;

  constructor(
    private visitorService: VisitorService,
    private cfr: ComponentFactoryResolver,
    private injector: Injector,
    private router: Router,
    private route: ActivatedRoute,
    private authQuery: AuthQuery,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.empFilters.push({ name: 'Country', options: this.countries, defaultValue: this.defaultValue });
    this.empFilters.push({ name: 'Status', options: this.stati, defaultValue: this.defaultValue });
    this.empFilters.push({ name: 'Days', options: this.days, defaultValue: this.defaultValue });
  }

  maxall: number = 20;
  /*
  getPageSizeOptions(): number[] {
    if (this.dataSource.paginator.length>this.maxall)
      return [5, 10, 20,  this.dataSource.paginator.length];
    else
      return [5, 10, 20, this.maxall];
    }
  }
  */

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

    this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;

    merge(this.filters$, this.searchTerm$.pipe(debounceTime(400), distinctUntilChanged()), this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap((term) => {
          this.isLoading = true;
          //var sTerm = term && typeof term == 'string' ? term.toString() : '';
          return this.getTableData$(
            this.paginator.pageIndex + 1,
            this.paginator.pageSize,
            this.sort.active,
            this.sort.direction,
            //sTerm.trim(),
            this.searchTerm$.getValue(),
            this.filters$.getValue()
          ).pipe(catchError(() => observableOf(null)));
        }),
        map((visTable) => {
          if (visTable == null) return [];
          this.totalItems = visTable.total;
          this.isLoading = false;

          this.stats = visTable.stats;

          return visTable.data.map((data) => {
            return <Visitor>{
              ...data,
              decharge_resp_civile: data.decharge_resp_civile == -1 ? 1 : 0,
              estimate: data.estimate == '-1' ? '1' : 0,
              garage_day1: data.garage_day1 == -1 ? 1 : 0,
              garage_day2: data.garage_day2 == -1 ? 1 : 0,
              lunch_day1: data.lunch_day1 == -1 ? 1 : 0,
              lunch_day2: data.lunch_day2 == -1 ? 1 : 0,
              attendance_event: data.attendance_event == -1 ? 1 : 0,
              hotel_stay_night: data.hotel_stay_night == -1 ? 1 : 0,
              transfer_arrival: data.transfer_arrival == -1 ? 1 : 0,
              transfer_return: data.transfer_return == -1 ? 1 : 0,
              flight_booked: data.flight_booked == -1 ? 1 : 0,

              //
              // -----------------------------------------------------
              // OFFEN OFFEN OFFEN
              // hier fehtl es noch ALLE felder entsprechend anzupassen
              // mit 1 und 0 bzw. qui und non bzw. 1234 bei den pulldowns etc.
              //
            };
          });
          //return visTable.data;
        })
      )
      .subscribe((visitors: Visitor[]) => {
        this.visitors$ = of(visitors);
        this.dataSource = new MatTableDataSource(visitors);

        //reset paginator
        //this.paginator.pageIndex = 1;
      });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchTerm$.next(filterValue);
  }

  applyEmpFilter(ob: MatSelectChange, empfilter: EmpFilter) {
    this.filterDictionary.set(empfilter.name, ob.value);
    var jsonString = JSON.stringify(Array.from(this.filterDictionary.entries()));
    this.filters$.next(jsonString);
  }

  getTableData$(pageNumber: Number, pageSize: Number, sort: string, order, searchTerm: string, filtersArgs: string) {
    // get data from server
    return this.visitorService.getVisitors(pageNumber, pageSize, sort, order, searchTerm, filtersArgs);
  }

  gotoLiveSystem() {
    console.log('route to backend ---- sso');

    // set the form params
    const access_token = this.authQuery.access_token;
    const userid = this.authQuery.userid;
    const fid = this.authQuery.fid;
    const role = this.authQuery.role;

    const myform = document.createElement('form');
    myform.method = 'POST';
    myform.action = environment.eplusSystem + '/belogin.php';
    myform.style.display = 'none';
    //myform.append('Content-Type', 'application/x-www-form-urlencoded');
    //myform.append('Accept', 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8');

    const inputAuth = document.createElement('input');
    inputAuth.type = 'hidden';
    inputAuth.name = 'Authorization';
    inputAuth.value = access_token;
    myform.appendChild(inputAuth);

    const inputUid = document.createElement('input');
    inputUid.type = 'hidden';
    inputUid.name = 'uid';
    inputUid.value = '' + userid;
    myform.appendChild(inputUid);

    const inputFid = document.createElement('input');
    inputFid.type = 'hidden';
    inputFid.name = 'fid';
    inputFid.value = '' + fid;
    myform.appendChild(inputFid);

    const inputRole = document.createElement('input');
    inputRole.type = 'hidden';
    inputRole.name = 'role';
    inputRole.value = '' + role;
    myform.appendChild(inputRole);

    // Print the payload to the console
    console.log('Payload:');
    console.log('Authorization:', access_token);
    console.log('uid:', userid);
    console.log('fid:', fid);
    console.log('role:', role);

    document.body.appendChild(myform);
    myform.target = '_blank';
    myform.submit();
  }

  showPageDetail(visitor_id: number) {
    console.log('load page: ' + visitor_id);

    // get the quiz page with given uuid
    let visitor: Visitor;
    visitor = <Visitor>this.dataSource.filteredData.find((element: Visitor) => element.t_visitorid == visitor_id);

    // load the component into the modal slide window
    //this.router.navigate(['/home/edit', visitor_id]);
    this.router.navigate([
      '/home/edit',
      { id: visitor_id, _uid: visitor.beuser_id, _fid: visitor_id, _role: 3, _country: visitor.country, ia: 1 },
    ]);
    //, {my_object: JSON.stringify(this.Obj)}]));

    //this.showVisitor(visitor);
    this.sidenav.open();
  }

  showVisitor(visitor: Visitor) {
    console.log('show the page');

    this.selectedVisitor = visitor;

    // load visitor - firststart
    //this.llStartPageEdit(visitor);
  }

  sidebarOnClose() {
    console.log('close');
    this.openSidebar = false;
  }

  beautyNamesColumns: string[] = [
    'Generalist tour operator',
    'Specialist tour operator',
    'Tour operator - Own retailing',
    'Tour operator - Retailing through travel agencies',
    'Tour operator member of a holding company',
    'Travel agency',
    'Incentive agency',
    'International incoming agency',
    'Coach company',
    'Car rental company',
    'Hotel booking call center',
    'Air carrier',
    'Sea carrier',
    'Road carrierv',
    'Rail carrier',

    'Number of employees in the company',
    'Volume of travellers Groups to France per year',
    'Volume of Individuals travellers to France per year',
    'Number of brochures per year with France featured',
    'Can we book on line on your website some products featuring France?',
    'Brochure online only',

    'Auvergne - Rhône-Alps',
    'Burgundy-Franche-Comté',
    'Brittany',
    'Corsica',
    'Eastern France',
    'Loire Valley',
    'French Alps',
    'Occitanie',
    'Nouvelle-Aquitaine',
    'Northern France',
    'Normandy',
    'Overseas',
    'Paris and its suburbs',
    'Pays de la Loire',
    'Provence-Alpes Côte dAzur',
    'All France',

    'Business',
    'Beach resorts',
    'Luxury products',
    'Cruises',
    'Barging',
    'Golf',
    'Youth tourism',
    'Summer resorts',
    'Nature',
    'Religious tourism',
    'tailored-made products',
    'Ski',
    'Cultural tours',
    'Champagne, Gastronomy and wine tours',
    'Urban tourism and city break',
    'Active holidays and hiking',

    'Auvergne - Rhône-Alps',
    'Burgundy-Franche-Comté',
    'Brittany',
    'Loire Valley',
    'Eastern France',
    'Corsica',
    'French Alps',
    'Occitanie',
    'Nouvelle-Aquitaine',
    'Northern France',
    'Normandy',
    'Overseas',
    'Paris and ist suburbs',
    'Pays de la Loire',
    'Provence-Alpes Côte dAzur',
    'All France',

    'Hotel group',
    '2-star hotel',
    '3-star hotel',
    '4-star and 5* hotel',
    'Palace hotels',
    'Vacation residence',
    'Holiday club',
    'B&B and private houses rental',
    'Restaurant',
    'Incoming agency',
    'Congress and incentive organisation',
    'Sightseeing & guide',
    'Air and train carrier',
    'Coach carrier',
    'Barging',
    'Leisure or entertainment park',
    'Museum, monument, castle, site',
    'Show, cabaret, theater',
    'Shopping Mall, department store',
    'Tourist office',
    'Others',
  ];

  additionalColumns: string[] = [
    'generalisttour',
    'specialisttour',
    'tourownretailing',
    'tourretailingtravelagencies',
    'touroperatormember',
    'travel',
    'incentive',
    'inernationalincoming',
    'coach',
    'carrental',
    'hotelbookingcallcenter',
    'aircarrier',
    'seacarrier',
    'roadcarrier',
    'railcarrier',

    'numberemployees',
    'volumegroups',
    'volumeindividuals',
    'numberbrochures',
    'onlinebooking',
    'palsace',

    'pauvergne',
    'pburgundy',
    'pbrittany',
    'pcorsica',
    'pchampagne',
    'ploirevalley',
    'pfranche',
    'plancuedoc',
    'plimousin',
    'pnordpasdecalais',
    'pnormandy',
    'poverseas',
    'pparis',
    'pwesternloire',
    'pprovence',
    'pall',

    'pobusiness',
    'pobeach',
    'pochampagne',
    'pocruises',
    'pobarging',
    'pogolf',
    'poyouth',
    'posummer',
    'ponature',
    'poreligious',
    'pofitness',
    'powinter',
    'pocultural',
    'pogastronomy',
    'pourban',
    'poactive',

    'sauvergne',
    'sburgundy',
    'sbrittany',
    'sloire',
    'schampagne',
    'scorsica',
    'sfranche',
    'slanguedoc',
    'slimousin',
    'snordpasdecalais',
    'snormandy',
    'soverseas',
    'sparis',
    'swesternloire',
    'sprovence',
    'sall',

    'khotelgroup',
    'k2starhotel',
    'k3starhotel',
    'k4starhotel',
    'palacehotels',
    'kvacation',
    'kholidayclub',
    'kbandb',
    'krestaurant',
    'kincomingagency',
    'kcongress',
    'gsightseeing',
    'kairtrain',
    'kcoach',
    'kbarging',
    'kleisure',
    'kmuseum',
    'kshow',
    'kmall',
    'ktouristoffice',
    'allergies',
  ];
  showAdditionalColumns() {
    this.displayedColumns = this.displayedColumns.concat(this.additionalColumns);
    console.log(this.displayedColumns);
  }

  //
  // ------------------------------- TOTAL SUM CALCULATIONS
  public calculateTotal(fieldname: string) {
    let total = 0;

    //this.visitors$.subscribe((visitors) => {
    const visitors = this.dataSource.data;
    switch (fieldname) {
      case 'country':
        total = visitors.reduce((accum, curr) => accum + (curr.country ? 1 : 0), 0);
        break;

      case 'decharge_resp_civile':
        total = visitors.reduce((accum, curr) => accum + (curr.decharge_resp_civile > 0 ? +curr.decharge_resp_civile : 0), 0);
        break;

      case 'estimate':
        total = visitors.reduce((accum, curr) => accum + +curr.estimate, 0);
        break;

      case 'chkcity1':
        total = visitors.reduce((accum, curr) => accum + (curr.chkcity1 > 0 ? +curr.chkcity1 : 0), 0);
        break;

      case 'chkcity2':
        total = visitors.reduce((accum, curr) => accum + (curr.chkcity2 > 0 ? +curr.chkcity2 : 0), 0);
        break;

      case 'garage_day1':
        total = visitors.reduce((accum, curr) => accum + (curr.garage_day1 > 0 ? +curr.garage_day1 : 0), 0);
        break;

      case 'garage_day2':
        total = visitors.reduce((accum, curr) => accum + (curr.garage_day2 > 0 ? +curr.garage_day2 : 0), 0);
        break;

      case 'lunch_day1':
        total = visitors.reduce((accum, curr) => accum + (curr.lunch_day1 > 0 ? +curr.lunch_day1 : 0), 0);
        break;

      case 'lunch_day2':
        total = visitors.reduce((accum, curr) => accum + (curr.lunch_day2 > 0 ? +curr.lunch_day2 : 0), 0);
        break;

      case 'attendance_event':
        total = visitors.reduce((accum, curr) => accum + (curr.attendance_event > 0 ? +curr.attendance_event : 0), 0);
        break;

      case 'hotel_stay_night':
        total = visitors.reduce((accum, curr) => accum + (curr.hotel_stay_night > 0 ? +curr.hotel_stay_night : 0), 0);
        break;

      case 'transfer_arrival':
        total = visitors.reduce((accum, curr) => accum + (curr.transfer_arrival > 0 ? +curr.transfer_arrival : 0), 0);
        break;

      case 'transfer_return':
        total = visitors.reduce((accum, curr) => accum + (curr.transfer_return > 0 ? +curr.transfer_return : 0), 0);
        break;

      case 'flight_booked':
        total = visitors.reduce((accum, curr) => accum + (curr.flight_booked > 0 ? +curr.flight_booked : 0), 0);
        break;

      default:
        // there is no default case
        break;
    }
    return total;
  }

  getCountryName(country: string) {
    switch (country) {
      case 'at':
        return 'Austria';
      case 'ba':
        return 'Bosnia-Herzegovina';
      case 'bg':
        return 'Bulgaria';
      case 'hr':
        return 'Croatia';
      case 'cz':
        return 'Czech Republic';
      case 'fr':
        return 'France';
      case 'de':
        return 'Germany';
      case 'hu':
        return 'Hungary';
      case 'xk':
        return 'Kosovo';
      case 'mk':
        return 'Macedonia';
      case 'mne':
        return 'Montenegro';
      case 'pl':
        return 'Poland';
      case 'ro':
        return 'Romania';
      case 'xs':
        return 'Serbia';
      case 'sk':
        return 'Slovakia';
      case 'si':
        return 'Slovenia';
      case 'ch':
        return 'Switzerland';
      default:
        return '';
    }
  }

  /*
  private async llStartPageEdit(visitor: Visitor) {
    const { FirststartComponent } = await import('../firststart/firststart.component');
    const dynFactory = this.cfr.resolveComponentFactory(FirststartComponent);

    this.quizContainer.clear();

    const { instance } = this.quizContainer.createComponent(dynFactory, undefined, this.injector);
    instance.visitor = visitor;
  }
  */
}
