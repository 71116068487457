<div id="logo" class="logo">
  <img src="./assets/logo-france.jpg" alt="Atout France" class="brand" />
</div>

<section (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave($event)">
  <video
    id="video"
    poster="./assets/video-preview.jpg"
    autoplay
    playsinline
    autoplay="autoplay"
    [muted]="mute"
    loop="loop"
    *ngIf="isTablet || isDesktop">
    <source [src]="video_source_dash" type="application/x-mpegURL" />
    <source [src]="video_source_webm" type="video/webm" />
    <source [src]="video_source_mp4" type="video/mp4" />
  </video>

  <div class="bg-image" *ngIf="isMobile" style="background-image: url('./assets/video-preview.jpg')">&nbsp;</div>

  <div *ngIf="isTablet">
    <div class="container-center-center">
      <button mat-button mat-raised-button color="primary" type="button" (click)="goLogin()">Explore Me</button>
    </div>
  </div>

  <div class="overlay" [@slideInOut]="backdropState">
    <div class="video-gradient"></div>
    <div class="video-pattern"></div>
  </div>

  <div class="controlbar" *ngIf="isDesktop || isTablet">
    <button mat-fab color="primary" aria-label="Example icon button with a delete icon" (click)="togglePlay()">
      <mat-icon>{{ playingIcon }}</mat-icon>
    </button>
    &nbsp;
    <button mat-fab color="accent" aria-label="Example icon button with a bookmark icon" (click)="toggleMute()">
      <mat-icon>{{ muteIcon }}</mat-icon>
    </button>
  </div>

  <div [@slideInOut]="animationState" #contentform class="myForm">
    <section class="section-border border-primary" style="position: relative; z-index: 100">
      <div class="container d-flex flex-column">
        <div class="row align-items-center justify-content-center gx-0 min-vh-100">
          <div
            class="col-12 col-md-6 col-lg-{{ colLarge }} french-border-top
            col-xl-{{ colXLarge }} py-8 py-md-11 wrap mat-elevation-z4 myLoginForm">
            <mat-tab-group dynamicHeight class="invisible-tabs" [(selectedIndex)]="activeTab">
              <mat-tab label="Welcome">
                <div class="example-small-box text-center">
                  <!-- Heading -->
                  <h1 class="mb-0 text-center"><span [innerHTML]="__txt_Welcome"></span> {{ _eventYear }}</h1>

                  <!-- Text -->
                  <span [innerHTML]="__txt_Welcome_Text"></span>

                  <button mat-button mat-raised-button color="primary" type="button" (click)="goLogin()">Start</button>
                </div>
              </mat-tab>

              /* ---------------------------------------- LOGIN ------------------------------------ */
              <mat-tab label="Login">
                <div class="example-small-box">
                  <!-- Heading -->
                  <h1 class="mb-0 text-center" style="font-size: 55px">Welcome</h1>

                  <!-- Text -->
                  <p class="mb-6 text-center text-muted">{{ __txt_subline }} {{ _eventYear }}</p>

                  <!-- Form -->
                  <form (ngSubmit)="login()" [formGroup]="loginForm" class="mb-3">
                    <!-- Email -->
                    <div class="form-group">
                      <label class="form-label" for="email"> E-Mail </label>
                      <input type="email" formControlName="email" id="email" class="form-control" required placeholder="name@address.com" />
                    </div>

                    <!-- Password -->
                    <div class="form-group mb-3">
                      <label class="form-label" for="password"> Password </label>
                      <input [type]="!eye ? 'password' : 'text'" formControlName="password" id="password" class="form-control" required />
                      <mat-icon class="field-icon toggle-password" style="padding-top: 10px" (click)="toggleEye()">{{ eyeIcon }}</mat-icon>
                    </div>

                    <!-- Submit -->
                    <div class="flex items-center justify-between">
                      <button mat-button mat-ripple class="btn-primary" type="submit">Sign In</button>

                      <button mat-button type="button" (click)="goForgot()">Forgot Password</button>
                    </div>
                  </form>

                  <p class="mb-0 fs-sm text-center text-muted">
                    <span (click)="showFirststart($event)">Don't</span> have an account yet
                    <button mat-button type="button" (click)="goSignup()">Register Now</button>
                  </p>
                </div>
              </mat-tab>

              /* ---------------------------------------- REGISTER NOW ------------------------------------ */
              <mat-tab label="New Registration">
                <div class="example-large-box">
                  <div class="example-small-box">
                    <!-- Heading -->
                    <h1 class="mb-0 text-center">New Registration</h1>

                    <!-- Text -->
                    <p class="mb-6 text-center text-muted">Please fill out the form.</p>

                    <!-- Form -->
                    <form (ngSubmit)="register()" [formGroup]="registerForm" class="mb-3">
                      <!-- Company -->
                      <div class="form-group">
                        <label class="form-label" for="company"> Company *</label>
                        <input
                          type="text"
                          class="form-control"
                          id="company"
                          formControlName="company"
                          [ngClass]="{ 'is-invalid': submitted && registerForm.controls.company.invalid }"
                          placeholder="Your Company" />
                        <div *ngIf="submitted && registerForm.controls.company.invalid" class="error">Company is required</div>
                      </div>

                      <!-- Firstname -->
                      <div class="form-group">
                        <label class="form-label" for="firstname"> Firstname *</label>
                        <input
                          type="text"
                          class="form-control"
                          id="firstname"
                          formControlName="firstname"
                          [ngClass]="{ 'is-invalid': submitted && registerForm.controls.firstname.invalid }"
                          placeholder="Your Firstname" />
                        <div *ngIf="submitted && registerForm.controls.firstname.invalid" class="error">Firstname is required</div>
                      </div>

                      <!-- Lastname -->
                      <div class="form-group">
                        <label class="form-label" for="lastname"> Lastname *</label>
                        <input
                          type="text"
                          class="form-control"
                          id="lastname"
                          formControlName="lastname"
                          [ngClass]="{ 'is-invalid': submitted && registerForm.controls.lastname.invalid }"
                          placeholder="Your Lastname" />
                        <div *ngIf="submitted && registerForm.controls.lastname.invalid" class="error">Lastname is required</div>
                      </div>

                      <!-- Position -->
                      <div class="form-group">
                        <label class="form-label" for="position"> Position *</label>
                        <input
                          type="text"
                          class="form-control"
                          id="position"
                          formControlName="position"
                          [ngClass]="{ 'is-invalid': submitted && registerForm.controls.position.invalid }"
                          placeholder="Your Position" />
                        <div *ngIf="submitted && registerForm.controls.position.invalid" class="error">Position is required</div>
                      </div>

                      <!-- E-Mail -->
                      <div class="form-group">
                        <label class="form-label" for="email"> E-Mail *</label>
                        <input
                          type="text"
                          class="form-control"
                          id="email"
                          formControlName="email"
                          [ngClass]="{ 'is-invalid': submitted && registerForm.controls.email.invalid }"
                          placeholder="name@email.com" />
                        <div *ngIf="submitted && registerForm.controls.email.invalid" class="error">E-Mail is required</div>
                      </div>

                      <!-- Country -->
                      <div class="form-group">
                        <label class="form-label" for="email"> Country *</label>

                        <select
                          class="form-control"
                          id="country"
                          formControlName="country"
                          [ngClass]="{ 'is-invalid': submitted && registerForm.controls.country.invalid }">
                          <option value="at">Austria</option>
                          <option value="ba">Bosnia-Herzegovina</option>
                          <option value="bg">Bulgaria</option>
                          <option value="hr">Croatia</option>
                          <option value="cz">Czech Republic</option>
                          <option value="fr">France</option>
                          <option value="de">Germany</option>
                          <option value="hu">Hungary</option>
                          <option value="xk">Kosovo</option>
                          <option value="mk">Macedonia</option>
                          <option value="mne">Montenegro</option>
                          <option value="pl">Poland</option>
                          <option value="ro">Romania</option>
                          <option value="xs">Serbia</option>
                          <option value="sk">Slovakia</option>
                          <option value="si">Slovenia</option>
                          <option value="ch">Switzerland</option>
                        </select>
                        <div *ngIf="submitted && registerForm.controls.country.invalid" class="error">Country is required</div>
                      </div>

                      <!-- exhibitor - visitor -->
                      <div class="form-group">
                        <label class="form-label" for="exhibitor"> I am coming as *</label>
                        <div class="flex items-center">
                          <input
                            id="exhibitor"
                            formControlName="role"
                            type="radio"
                            value="exhibitor"
                            checked
                            class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                          <label for="exhibitor" class="ml-3 block text-sm font-medium text-gray-700">Exhibitor</label>
                        </div>

                        <div class="flex items-center">
                          <input
                            id="visitor"
                            formControlName="role"
                            type="radio"
                            value="visitor"
                            class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                          <label for="visitor" class="ml-3 block text-sm font-medium text-gray-700">Visitor</label>
                        </div>
                      </div>

                      <!-- Submit -->
                      <button mat-button class="btn w-100 btn-primary" type="submit">Register Now</button>

                      <button mat-button type="button" (click)="goLogin()">back</button>
                    </form>
                  </div>
                </div>
              </mat-tab>

              /* ---------------------------------------- FORGOT PASSWORD ------------------------------------ */

              <mat-tab label="Forgot Password">
                <div class="example-large-box">
                  <!-- Heading -->
                  <h1 class="mb-0 text-center">Forgot Password</h1>

                  <!-- Text -->
                  <p class="mb-6 text-center text-muted">Fill out the form.</p>
                  <form (ngSubmit)="forgot()" [formGroup]="forgotForm">
                    <div class="mb-3">
                      <label for="email" class="form-label">Email</label>
                      <input type="email" id="email" class="form-control" name="email" placeholder="Enter Your Email" required="" />
                    </div>
                    <div class="mb-3 d-grid">
                      <button mat-button type="submit" class="btn btn-primary">Reset Password</button>
                    </div>
                    <span>Don't have an account? <button mat-button type="button" (click)="goSignup()">Register Now</button></span>
                  </form>
                  <button mat-button type="button" (click)="goLogin()">back</button>
                </div>
              </mat-tab>

              /* ---------------------------------------- FIRSTSTART ASSISTANT EXHIBITOR ------------------------------------ */

              <mat-tab label="Firststart">
                <div class="example-large-box">
                  <!-- Heading -->

                  <form [formGroup]="firstExhibitorForm">
                    <mat-horizontal-stepper #stepper labelPosition="bottom" (selectionChange)="setIndex($event)" (click)="triggerClick()">
                      <mat-step label="Société">
                        <div class="step-container d-flex align-items-start flex-column">
                          <div class="container-fluid">
                            <div class="form">
                              <div class="grid gap-6 mb-6 md:grid-cols-2">
                                <div>
                                  <label for="adresse" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >Adresse *</label
                                  >
                                  <input
                                    type="text"
                                    id="adresse"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder=""
                                    formControlName="adresse"
                                    [ngClass]="{ 'is-invalid': submitted && firstExhibitorForm.controls['adresse'].invalid }" />
                                  <div *ngIf="submitted && firstExhibitorForm.controls['adresse'].invalid" class="error">
                                    Adresse is required
                                  </div>
                                </div>

                                <div>
                                  <label for="plz" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">CP *</label>
                                  <input
                                    type="text"
                                    id="plz"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder=""
                                    formControlName="plz"
                                    [ngClass]="{ 'is-invalid': submitted && firstExhibitorForm.controls['plz'].invalid }" />
                                  <div *ngIf="submitted && firstExhibitorForm.controls['plz'].invalid" class="error">CP is required</div>
                                </div>
                                <div>
                                  <label for="stadt" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Ville *</label>
                                  <input
                                    type="text"
                                    id="stadt"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder=""
                                    formControlName="stadt"
                                    [ngClass]="{ 'is-invalid': submitted && firstExhibitorForm.controls['stadt'].invalid }" />
                                  <div *ngIf="submitted && firstExhibitorForm.controls['stadt'].invalid" class="error">
                                    Ville is required
                                  </div>
                                </div>
                                <div>
                                  <label for="phone" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >Telephone *</label
                                  >
                                  <input
                                    type="text"
                                    id="telefon"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder=""
                                    formControlName="telefon"
                                    [ngClass]="{ 'is-invalid': submitted && firstExhibitorForm.controls['telefon'].invalid }" />
                                  <div *ngIf="submitted && firstExhibitorForm.controls['telefon'].invalid" class="error">
                                    Telephone is required
                                  </div>
                                </div>
                                <div>
                                  <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >Company E-Mail *</label
                                  >
                                  <input
                                    type="text"
                                    id="email"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder=""
                                    formControlName="email"
                                    [ngClass]="{ 'is-invalid': submitted && firstExhibitorForm.controls['email'].invalid }" />
                                  <div *ngIf="submitted && firstExhibitorForm.controls['email'].invalid" class="error">
                                    Company E-Mail is required
                                  </div>
                                </div>
                                <div>
                                  <label for="website" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >Site web *</label
                                  >
                                  <input
                                    type="text"
                                    id="web"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder=""
                                    formControlName="web"
                                    [ngClass]="{ 'is-invalid': submitted && firstExhibitorForm.controls['web'].invalid }" />
                                  <div *ngIf="submitted && firstExhibitorForm.controls['web'].invalid" class="error">
                                    Website is required
                                  </div>
                                </div>

                                <div>
                                  <label for="taxid_uid" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >n° de TVA intracomm *</label
                                  >
                                  <input
                                    type="text"
                                    id="taxid_uid"
                                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder=""
                                    formControlName="taxid_uid"
                                    [ngClass]="{ 'is-invalid': submitted && firstExhibitorForm.controls['taxid_uid'].invalid }" />
                                  <div *ngIf="submitted && firstExhibitorForm.controls['taxid_uid'].invalid" class="error">
                                    n° de TVA intracomm is required
                                  </div>
                                </div>
                                <div>
                                  <label for="website" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >Catégorie *</label
                                  >
                                  <select
                                    id="category"
                                    name="category"
                                    formControlName="category"
                                    class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    [ngClass]="{ 'is-invalid': submitted && firstExhibitorForm.controls['category'].invalid }">
                                    <option *ngFor="let category of EVENT_CATEGORIES" [value]="category">
                                      {{ category }}
                                    </option>
                                  </select>
                                  <!--
                                  <select
                                    id="category"
                                    name="category"
                                    formControlName="category"
                                    class="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    [ngClass]="{ 'is-invalid': submitted && firstExhibitorForm.controls['category'].invalid }">
                                    <option value="Accommodation" selected>Accommodation</option>
                                    <option value="Boating Holidays">Boating Holidays</option>
                                    <option value="Carriers">Carriers</option>
                                    <option value="Cultural Sites">Cultural Sites</option>
                                    <option value="Excursions / Entertainment">Excursions</option>
                                    <option value="Incoming services">Incoming services</option>
                                    <option value="Luxury offers">Luxury offers</option>
                                    <option value="MICE Services">MICE Services</option>
                                    <option value="Ski">Ski</option>
                                    <option value="Tourist offices">Tourist offices</option>
                                    <option value="Shopping">Shopping</option>
                                    <option value="Others">Others</option>
                                  </select>
                                  -->
                                  <div *ngIf="submitted && firstExhibitorForm.controls['category'].invalid" class="error">
                                    Catégorie is required
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="step-footer">
                          <button mat-raised-button matStepperNext color="primary">Next</button>
                        </div>
                      </mat-step>

                      <mat-step label="Région">
                        <div class="step-container d-flex align-items-start flex-column">
                          <div class="container-fluid">
                            <div class="form">
                              <div class="row">
                                <div>
                                  <p class="font-semibold">Veuillez remplir les informations sur la région.</p>
                                </div>

                                <div class="grid gap-6 mb-6 mt-6 md:grid-cols-2" formGroupName="regionsGroup">
                                  <div class="flex items-center">
                                    <input id="auvergne" formControlName="auvergne" type="checkbox" class="form-check-input" />
                                    <label for="auvergne" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                      >Auvergne - Rhône-Alpes</label
                                    >
                                  </div>

                                  <div class="flex items-center">
                                    <input id="bourgogne" formControlName="bourgogne" type="checkbox" class="form-check-input" />
                                    <label for="bourgogne" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                      >Bourgogne - Franche-Compté</label
                                    >
                                  </div>
                                  <div class="flex items-center">
                                    <input id="bretagne" formControlName="bretagne" type="checkbox" class="form-check-input" />
                                    <label for="bretagne" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Bretagne</label>
                                  </div>
                                  <div class="flex items-center">
                                    <input id="centreloire" formControlName="centreloire" type="checkbox" class="form-check-input" />
                                    <label for="centreloire" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                      >Centre Val de Loire</label
                                    >
                                  </div>

                                  <div class="flex items-center">
                                    <input id="corse" formControlName="corse" type="checkbox" class="form-check-input" />
                                    <label for="corse" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Corse</label>
                                  </div>
                                  <div class="flex items-center">
                                    <input id="champagne" formControlName="champagne" type="checkbox" class="form-check-input" />
                                    <label for="champagne" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                      >Grand Est</label
                                    >
                                  </div>
                                  <div class="flex items-center">
                                    <input id="nordcalais" formControlName="nordcalais" type="checkbox" class="form-check-input" />
                                    <label for="nordcalais" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                      >Hauts de France</label
                                    >
                                  </div>
                                  <div class="flex items-center">
                                    <input id="normandie" formControlName="normandie" type="checkbox" class="form-check-input" />
                                    <label for="normandie" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                      >Normandie</label
                                    >
                                  </div>

                                  <div class="flex items-center">
                                    <input id="aquitaine" formControlName="aquitaine" type="checkbox" class="form-check-input" />
                                    <label for="aquitaine" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                      >Nouvelle-Aquitaine</label
                                    >
                                  </div>
                                  <div class="flex items-center">
                                    <input id="languedoc" formControlName="languedoc" type="checkbox" class="form-check-input" />
                                    <label for="languedoc" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                      >Occitanie</label
                                    >
                                  </div>
                                  <div class="flex items-center">
                                    <input id="outremer" formControlName="outremer" type="checkbox" class="form-check-input" />
                                    <label for="outremer" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                      >Outre-mer</label
                                    >
                                  </div>
                                  <div class="flex items-center">
                                    <input id="paris" formControlName="paris" type="checkbox" class="form-check-input" />
                                    <label for="paris" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                      >Paris Ile de France</label
                                    >
                                  </div>

                                  <div class="flex items-center">
                                    <input id="paysloire" formControlName="paysloire" type="checkbox" class="form-check-input" />
                                    <label for="paysloire" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                      >Pays de la Loire</label
                                    >
                                  </div>
                                  <div class="flex items-center">
                                    <input id="paca" formControlName="paca" type="checkbox" class="form-check-input" />
                                    <label for="paca" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                      >Provence-Alpes Côte d'Azur</label
                                    >
                                  </div>
                                  <div class="flex items-center">
                                    <input id="multiregions" formControlName="multiregions" type="checkbox" class="form-check-input" />
                                    <label for="multiregions" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                      >France entière</label
                                    >
                                  </div>

                                  <!-- dynamic fields regions requested  -->
                                  <ng-container *ngFor="let key of getKeysRegions(); let i = index">
                                    <div *ngIf="show_dyn_regions_ex[i]" class="flex items-center">
                                      <input [id]="key" [formControlName]="key" type="checkbox" class="form-check-input" />
                                      <label [for]="key" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                        {{ caption_dyn_regions_ex[key] }}
                                      </label>
                                    </div>
                                  </ng-container>
                                  <!-- end dyn fields -->

                                  <div *ngIf="submitted && firstExhibitorForm.controls['regionsGroup'].invalid" class="error mb-3">
                                    <p>At least one box is required to check.</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="step-footer">
                          <button mat-raised-button matStepperPrevious>Back</button> &nbsp;
                          <button mat-raised-button matStepperNext color="primary">Next</button>
                        </div>
                      </mat-step>

                      <mat-step label="Activité">
                        <div class="step-container d-flex align-items-start flex-column">
                          <div class="container-fluid">
                            <div class="form">
                              <div class="row">
                                <div>
                                  <p class="font-semibold">Veuillez remplir les informations sur l'activité.</p>
                                </div>

                                <div class="grid gap-6 mb-6 mt-6 md:grid-cols-2" formGroupName="activitiesGroup">
                                  <div class="flex items-center">
                                    <input
                                      id="agencereceptive"
                                      formControlName="agencereceptive"
                                      type="checkbox"
                                      class="form-check-input" />
                                    <label for="agencereceptive" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{
                                      activities_fr[0]
                                    }}</label>
                                  </div>
                                  <div class="flex items-center">
                                    <input
                                      id="centralereservation"
                                      formControlName="centralereservation"
                                      type="checkbox"
                                      class="form-check-input" />
                                    <label for="centralereservation" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{
                                      activities_fr[1]
                                    }}</label>
                                  </div>
                                  <div class="flex items-center">
                                    <input id="destination" formControlName="destination" type="checkbox" class="form-check-input" />
                                    <label for="destination" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{
                                      activities_fr[2]
                                    }}</label>
                                  </div>
                                  <div class="flex items-center">
                                    <input id="excursion" formControlName="excursion" type="checkbox" class="form-check-input" />
                                    <label for="excursion" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{
                                      activities_fr[3]
                                    }}</label>
                                  </div>

                                  <div class="flex items-center">
                                    <input id="groupement" formControlName="groupement" type="checkbox" class="form-check-input" />
                                    <label for="groupement" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{
                                      activities_fr[4]
                                    }}</label>
                                  </div>
                                  <div class="flex items-center">
                                    <input
                                      id="locationfluviale"
                                      formControlName="locationfluviale"
                                      type="checkbox"
                                      class="form-check-input" />
                                    <label for="locationfluviale" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{
                                      activities_fr[5]
                                    }}</label>
                                  </div>
                                  <div class="flex items-center">
                                    <input id="musee" formControlName="musee" type="checkbox" class="form-check-input" />
                                    <label for="musee" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{
                                      activities_fr[6]
                                    }}</label>
                                  </div>
                                  <div class="flex items-center">
                                    <input id="transporteur" formControlName="transporteur" type="checkbox" class="form-check-input" />
                                    <label for="transporteur" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{
                                      activities_fr[12]
                                    }}</label>
                                  </div>

                                  <div class="flex items-center">
                                    <input id="autre" formControlName="autre" type="checkbox" class="form-check-input" />
                                    <label for="autre" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{
                                      activities_fr[13]
                                    }}</label>
                                  </div>
                                  <div class="flex items-center">
                                    <input id="offreski" formControlName="offreski" type="checkbox" class="form-check-input" />
                                    <label for="offreski" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{
                                      activities_fr[7]
                                    }}</label>
                                  </div>
                                  <div class="flex items-center">
                                    <input id="ski_station" formControlName="ski_station" type="checkbox" class="form-check-input" />
                                    <label for="ski_station" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{
                                      activities_fr[8]
                                    }}</label>
                                  </div>
                                  <div class="flex items-center">
                                    <input id="ski_rental" formControlName="ski_rental" type="checkbox" class="form-check-input" />
                                    <label for="ski_rental" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{
                                      activities_fr[9]
                                    }}</label>
                                  </div>

                                  <div class="flex items-center">
                                    <input
                                      id="residencetourisme"
                                      formControlName="residencetourisme"
                                      type="checkbox"
                                      class="form-check-input" />
                                    <label for="residencetourisme" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{
                                      activities_fr[10]
                                    }}</label>
                                  </div>
                                  <div class="flex items-center">
                                    <input id="restaurant" formControlName="restaurant" type="checkbox" class="form-check-input" />
                                    <label for="restaurant" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{
                                      activities_fr[11]
                                    }}</label>
                                  </div>

                                  <!-- dynamic fields activities requested  -->
                                  <ng-container *ngFor="let key of getKeysActivities(); let i = index">
                                    <div *ngIf="show_dyn_activities_ex[i]" class="flex items-center">
                                      <input [id]="key" [formControlName]="key" type="checkbox" class="form-check-input" />
                                      <label [for]="key" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                        {{ caption_dyn_activities_ex[key] }}
                                      </label>
                                    </div>
                                  </ng-container>
                                  <!-- end dyn fields -->

                                  <div *ngIf="submitted && firstExhibitorForm.controls['activitiesGroup'].invalid" class="error mb-3">
                                    <p>At least one box is required to check.</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="step-footer">
                          <button mat-raised-button matStepperPrevious>Back</button> &nbsp;
                          <button mat-raised-button matStepperNext color="primary">Next</button>
                        </div>
                      </mat-step>

                      <mat-step label="Offre">
                        <div class="step-container d-flex align-items-start flex-column">
                          <div class="container-fluid">
                            <div class="row mb-6 mt-6">
                              <label for="beschreibung" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >Descriptif de votre activité / offre</label
                              >
                              <textarea
                                id="beschreibung"
                                formControlName="beschreibung"
                                rows="4"
                                class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder=""></textarea>
                              <div *ngIf="submitted && firstExhibitorForm.controls['beschreibung'].invalid" class="error">
                                Descriptif de votre activité / offre is required
                              </div>
                            </div>

                            <div class="row mb-6 mt-6">
                              <label for="info1" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nouveautés</label>
                              <textarea
                                id="info1"
                                formControlName="info1"
                                rows="4"
                                class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder=""></textarea>
                              <div *ngIf="submitted && firstExhibitorForm.controls['info1'].invalid" class="error">
                                Nouveautés is required
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="step-footer">
                          <button mat-raised-button matStepperPrevious>Back</button> &nbsp;
                          <button mat-raised-button matStepperNext color="primary">Next</button>
                        </div>
                      </mat-step>

                      <mat-step label="Images">
                        <div class="step-container d-flex align-items-start flex-column">
                          <div class="container-fluid">
                            <div class="form">
                              <div class="row">
                                <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                  >Votre photo de profil</label
                                >
                                <file-pond
                                  id="myPondProfile"
                                  name="myPondProfile"
                                  [options]="pondOptions"
                                  [files]="pondFiles"
                                  (oninit)="pondHandleInit()"
                                  (onaddfile)="pondHandleAddFile($event)"
                                  (onactivatefile)="pondHandleActivateFile($event)">
                                </file-pond>
                                <br />
                                <img
                                  *ngIf="_profile_image"
                                  class="aspect-[4/5] w-52 flex-none rounded-2xl object-cover"
                                  src="{{ imageStorage }}{{ _profile_image }}"
                                  alt="" />

                                <p>&nbsp;</p>
                                <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                  >Your Company Logo</label
                                >
                                <file-pond
                                  id="myPondLogo"
                                  name="myPondLogo"
                                  [options]="pondOptions"
                                  [files]="pondFiles"
                                  (oninit)="pondHandleInit()"
                                  (onaddfile)="pondHandleAddFile($event)"
                                  (onactivatefile)="pondHandleActivateFile($event)">
                                </file-pond>
                                <br />
                                <img
                                  *ngIf="_logo"
                                  class="aspect-[4/5] w-52 flex-none rounded-2xl object-cover"
                                  src="{{ imageStorage }}{{ _logo }}"
                                  alt="" />
                                <p>&nbsp;</p>

                                <div class="row">
                                  <div class="col-12 col-lg-4">
                                    <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                      >Image 1</label
                                    >
                                    <file-pond
                                      id="myPondImage1"
                                      name="myPondImage1"
                                      [options]="pondOptions"
                                      [files]="pondFiles"
                                      (oninit)="pondHandleInit()"
                                      (onaddfile)="pondHandleAddFile($event)"
                                      (onactivatefile)="pondHandleActivateFile($event)">
                                    </file-pond>
                                  </div>
                                  <br />
                                  <img
                                    *ngIf="_bild1"
                                    class="aspect-[4/5] w-52 flex-none rounded-2xl object-cover"
                                    src="{{ imageStorage }}{{ _bild1 }}"
                                    alt="" />
                                  <div class="col-12 col-lg-4">
                                    <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                      >Image 2</label
                                    >
                                    <file-pond
                                      id="myPondImage2"
                                      name="myPondImage2"
                                      [options]="pondOptions"
                                      [files]="pondFiles"
                                      (oninit)="pondHandleInit()"
                                      (onaddfile)="pondHandleAddFile($event)"
                                      (onactivatefile)="pondHandleActivateFile($event)">
                                    </file-pond>
                                  </div>
                                  <br />
                                  <img
                                    *ngIf="_bild2"
                                    class="aspect-[4/5] w-52 flex-none rounded-2xl object-cover"
                                    src="{{ imageStorage }}{{ _bild2 }}"
                                    alt="" />
                                  <div class="col-12 col-lg-4">
                                    <label for="message" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                      >Image 3</label
                                    >
                                    <file-pond
                                      id="myPondImage3"
                                      name="myPondImage3"
                                      [options]="pondOptions"
                                      [files]="pondFiles"
                                      (oninit)="pondHandleInit()"
                                      (onaddfile)="pondHandleAddFile($event)"
                                      (onactivatefile)="pondHandleActivateFile($event)">
                                    </file-pond>
                                  </div>
                                  <br />
                                  <img
                                    *ngIf="_bild3"
                                    class="aspect-[4/5] w-52 flex-none rounded-2xl object-cover"
                                    src="{{ imageStorage }}{{ _bild3 }}"
                                    alt="" />
                                </div>
                              </div>

                              <ng-container *ngIf="_client === 'workshop-france'; else otherClient">
                                <div *ngIf="submitted && firstExhibitorForm.invalid" class="error">Please check the mandatory fields.</div>
                                <p>&nbsp;</p>
                                <div class="step-footer">
                                  <button mat-raised-button matStepperPrevious>Back</button> &nbsp;
                                  <button mat-raised-button (click)="submitFirststart()" color="primary">Submit</button>
                                </div>
                              </ng-container>

                              <ng-template #otherClient>
                                <button mat-raised-button matStepperPrevious>Back</button> &nbsp;
                                <button mat-raised-button matStepperNext color="primary">Next</button>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                      </mat-step>

                      <mat-step label="Terms" *ngIf="_showTerms">
                        <div class="step-container d-flex align-items-start flex-column">
                          <div class="container-fluid">
                            <fieldset class="mt-6" *ngIf="showDays">
                              <legend class="contents text-sm font-semibold leading-6 text-gray-900">Which Days will you attend?</legend>
                              <div class="mt-4 space-y-4">
                                <div class="flex items-top">
                                  <input
                                    type="checkbox"
                                    formControlName="chkcity1"
                                    id="chkcity1"
                                    class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                  <label for="chkcity1" class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                    {{ _day1 }}</label
                                  >
                                </div>

                                <div class="flex items-top">
                                  <input
                                    type="checkbox"
                                    formControlName="chkcity2"
                                    id="chkcity2"
                                    class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                  <label for="chkcity2" class="ml-3 block mb-2 text-sm font-medium leading-6 text-gray-900 dark:text-white">
                                    {{ _day2 }}</label
                                  >
                                </div>
                              </div>
                            </fieldset>

                            <div class="row mb-6 mt-6">
                              <label for="special_wishes" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                >Souhaits particuliers</label
                              >
                              <textarea
                                id="special_wishes"
                                formControlName="special_wishes"
                                rows="4"
                                class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder=""></textarea>
                            </div>

                            <div class="card bg-light mb-3 termsCard">
                              <div class="card-body">
                                <div class="form-group form-check" *ngIf="showStorno">
                                  <input type="checkbox" formControlName="storno_accepted" id="storno_accepted" class="form-check-input" />
                                  <label for="storno_accepted" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >Accept <a href="{{ linkStorno }}" target="_blank">Cancellation Terms</a></label
                                  >
                                  <div *ngIf="submitted && firstExhibitorForm.controls.storno_accepted.invalid" class="error">
                                    Accept Cancellation Terms is required
                                  </div>
                                </div>

                                <div class="form-group form-check" *ngIf="showDSGVO">
                                  <input type="checkbox" formControlName="dsgvo_accepted" id="dsgvo_accepted" class="form-check-input" />
                                  <label for="dsgvo_accepted" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >Accept <a href="{{ linkDSGVO }}" target="_blank">General Data Protection Regulation (GDPR)</a></label
                                  >
                                  <div *ngIf="submitted && firstExhibitorForm.controls.dsgvo_accepted.invalid" class="error">
                                    Accept General Data Protection Regulation is required
                                  </div>
                                </div>

                                <div class="form-group form-check" *ngIf="_showTerms">
                                  <input type="checkbox" formControlName="agb_accepted" id="agb_accepted" class="form-check-input" />
                                  <label for="agb_accepted" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                    >Accept <a href="{{ linkTerms }}" target="_blank">Terms & Conditions</a></label
                                  >
                                  <div *ngIf="submitted && firstExhibitorForm.controls.agb_accepted.invalid" class="error">
                                    Accept Terms & Conditions is required
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div *ngIf="submitted && firstExhibitorForm.invalid" class="error">Please check the mandatory fields.</div>
                        <p>&nbsp;</p>
                        <div class="step-footer">
                          <button mat-raised-button matStepperPrevious>Back</button> &nbsp;
                          <button mat-raised-button (click)="submitFirststart()" color="primary">Submit</button>
                        </div>
                      </mat-step>

                      <ng-template matStepperIcon="done">
                        <mat-icon>done</mat-icon>
                      </ng-template>
                      <ng-template matStepperIcon="edit">
                        <mat-icon>no icon</mat-icon>
                      </ng-template>
                      <ng-template matStepperIcon="number">
                        <mat-icon>no icon</mat-icon>
                      </ng-template>
                    </mat-horizontal-stepper>
                  </form>
                </div>
              </mat-tab>

              /* ---------------------------------------- RETGISTER SUCCESS ------------------------------------ */

              <mat-tab label="Successful Registration">
                <div class="example-large-box">
                  <!-- Heading -->
                  <h1 class="mb-0 text-center">Successful Registration</h1>

                  <!-- Text -->
                  <p class="mb-6 text-center text-muted">
                    Thank you for registering to {{ __txt_subline }}. <br />
                    As soon as we review your data we send you the invitation.
                  </p>

                  <button mat-button type="button" (click)="goLogin()">back</button>
                </div>
              </mat-tab>

              <!-- end tabs -->
            </mat-tab-group>
          </div>
        </div>
        <!-- / .row -->
      </div>
    </section>
  </div>
</section>
