import { env } from './.env';

export const environment = {
  production: true,
  client: 'french-rendez-vous', // workshop-france | france-excellence | french-rendez-vous
  version: env['npm_package_version'],
  defaultLanguage: 'en-EN',
  supportedLanguages: ['en-EN'],
  api_key: 'atout-frech-1234',

  // server rest api
  serverUrl: 'https://www.french-rendez-vous.com/api/v3',
  eplusSystem: 'https://www.french-rendez-vous.com/eplus',

  // -------------------
  day_1: '26.05.2025',
  day_2: '27.05.2025',
  linkTerms: 'https://www.french-rendez-vous.com/eplus/_fileDB/terms.pdf',
  linkDSGVO: '#',
  linkStorno: '#',

  show_media_info: true, // for visitors - show the text-info to the permission that photos are taken
  linkMedia: '#',

  event_year: '2025',
  veranstalter: 'Atout France',
  hotel_info: 'Imperial Riding School', // lunch at {the DoubleTree by Hilton Vienna Schönbrunn}
  hotel_parking: 'I would like Atout France to reserve a parking place in my name for the', // Yes, I would like Atout France to reserve a parking place in my name for the
  event_space: 'AULA of sciences', // evening at the {Schönbrunn Palace} on 22.05.24
  event_date: '26.05 2025', // evening at the Schönbrunn Palace on {22.05.24}
  book_night: '26.05/27.05 2025', // book a room in my name for the night of {22.05/23.05 2024} at the
  cancel_date: 'May 5th 2025', // for all cancellations after {May 10th 2023}, the
  event_dates: '26th May and 27th May 2025', // {31st May and 1st June 2023} in Vienna - Austria
  veranstaltungs_land: 'at', // country code
  county_selection: true, // true or false: einfachere version (nur veranstaltungsland und andere)
  show_accounting_info: false, // if the button accunting-info should beshown

  terms_check: true, // set if the terms should be checked
  taxid_required: true, // set if the tax id should be checked

  // list of diffrent terms and conditions
  termsListA: ['at', 'sk', 'si'], //['at', 'sk', 'si'];
  termsListB: ['cz', 'hu'], //  ['at', 'ba', 'bg', 'hr', 'cz', 'fr', 'hu', 'xk', 'mk', 'mne', 'pl', 'ro', 'xs', 'sk', 'si', 'ch'], //['cz', 'hu'];

  //
  /* -----------------*/
  event_finish: false, // true or false if event is done and gallery should be shown
  show_gallery: false, // show the gallery - if false -> show the login
  showGalleryAlways: false, // show the gallery always -> not only for login users

  // video - frontpage
  video_source_dash:
    'https://d2iujko1ysket6.cloudfront.net/videos/ontarget-management-4-austria-7eb7b099/atout-france/explorefrance_2023_v10_en_finale.mp4/hls/explorefrance_2023_v10_en_finale.m3u8',
  video_source_webm: './assets/Explorefrance 2023 V10 En Finale-1.webm',
  video_source_mp4: './assets/EXPLOREFRANCE_2023_V10_EN_FINALE.mp4 ',

  //
  /** --------------------------------- */
  txt_EventName: 'French Rendez-Vous',
  txt_Subline: 'french-rendez-vous',
  txt_Event_Domain: 'french-rendez-vous.com',
  txt_Welcome: '<span style="font-size: 1.4em">Welcome</span> <br />French Rendez-Vous',
  txt_Welcome_Text: `<p class="mb-6 text-center text-muted">France comes to you….</p>
  <p>
    The France-Workshop in Central Europe <br />
    – a must-attend event! <br />
    26th and 27th May 2025 in Vienna - Austria <br />
    Meet about 20 French partners from the tourism industry during one and a half days of <br />
    professional workshop and a“France” Evening full of Interaction.
  </p>`,
  txt_Event_City: 'Vienna', // Vienna
  txt_Hotel_Night: 'Imperial Riding School', // Hotel Bayerischer Hof
  txt_gallery_location: 'in Vienna - Austria', // in Vienna - Austria

  // VISITOR DYN FIELDS
  show_dyn_identity: [true, true, true, true, true],
  caption_dyn_identity: {
    carrental: 'Car rental company',
    aircarrier: 'Air carrier',
    seacarrier: 'Sea carrier',
    roadcarrier: 'Road carrier', // 'Corporate', // Road carrier
    railcarrier: 'Rail carrier', // 'PCO', // Rail carrier
  },
  show_dyn_programming: [false, false, false, false],
  caption_dyn_programming: {
    spicardy: '',
    spoitou: '',
    srhonealps: '',
    sfrenchriviera: '',
  },
  show_dyn_products: [true, true, true, true, true, true, true, true],
  caption_dyn_products: {
    pobusiness: 'Business',
    poreligious: 'Religious tourism',
    poyouth: 'Youth tourism',
    posummer: 'Summer resorts',
    paquitaine: 'Meetings/Conferences',
    plorraine: 'Company Events',
    pmidi: 'Incentives',
    ppicardy: 'Congresses',
  },
  show_dyn_regions: [false, false, false, false],
  caption_dyn_regions: {
    ppoitou: '',
    prhonealps: '',
    pfrenchriviera: '',
    salsace: '',
  },
  show_dyn_productsreq: [true, true, true, true, true, true, true, true, true],
  caption_dyn_productsreq: {
    k2starhotel: '2-star hotel',
    k3starhotel: '3-star hotel',
    k4starhotel: '4-star',
    kleisure: 'Leisure or entertainment park',
    kshow: 'Show, cabaret, theater',
    saquitaine: '5-star hotel',
    slorraine: 'Wine / gastronomy tours',
    smidi: 'Concierge',
    allergies: 'Activities',
  },

  // EXHIBITORS DYN FIELDS
  show_dyn_regions_ex: [false, false, false, false, false],
  caption_dyn_regions_ex: {
    alsace: '',
    limousin: '',
    lorraine: '',
    midipyrenes: '',
    poitoucharentes: '',
  },
  show_dyn_activities_ex: [true, true, false, false, false],
  caption_dyn_activities_ex: {
    rhonealpes: 'Croisères',
    riveria: 'Oenotourisme',
    monaco: '',
    thaiti: '',
    luxury: '',
  },

  //
  // EVENT CATEGORIES
  //
  EVENT_CATEGORIES: [
    'Accommodation',
    'Boating Holidays',
    'Carriers',
    'Cultural Sites',
    'Excursions / Entertainment',
    'Incoming services',
    'Luxury offers',
    'MICE Services',
    'Ski',
    'Tourist offices',
    'Shopping',
    'Wine Tourism',
    'Cruises',
    'Others',
  ],
};
