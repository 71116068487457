import { Component, Input, Output, EventEmitter, SimpleChanges, OnInit, OnChanges, HostListener, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { MatSnackBar } from '@angular/material/snack-bar';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

import { environment } from '../../environments/environment';
//import { Logger } from '@core';
//import { AuthQuery } from '@app/auth/auth.query';
//import { JournalService, MailService, Mail } from '@core';
//import { AuthDataService } from '@app/auth/auth-data.service';

import { requireAtLeastOneCheckboxValidator } from '../@shared/validation/require-checkboxes-to-be-checked.validator';

import { ExhibitorService, Exhibitor, ExhibitorTable, ExhibitorSingle } from '../services/exhibitor.service';

//const log = new Logger('Login');

@Component({
  selector: 'app-profile-exhibitor',
  templateUrl: './profile-exhibitor.component.html',
  styleUrls: ['./profile-exhibitor.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class ProfileExhibitorComponent implements OnInit {
  // terms check
  _terms_check = environment.terms_check;
  _taxid_required = environment.taxid_required;

  // texting and content
  __txt_subline = environment.txt_Subline;
  __txt_eventDomain = environment.txt_Event_Domain;
  __txt_Welcome = environment.txt_Welcome;
  __txt_Welcome_Text = environment.txt_Welcome_Text;

  // environment
  _eventYear = environment.event_year;
  _hotel_info = environment.hotel_info;
  _event_space = environment.event_space;
  _event_date = environment.event_date;
  _book_night = environment.book_night;
  _cancel_date = environment.cancel_date;

  // dynamic fields
  show_dyn_regions_ex = environment.show_dyn_regions_ex;
  caption_dyn_regions_ex = environment.caption_dyn_regions_ex;

  show_dyn_activities_ex = environment.show_dyn_activities_ex;
  caption_dyn_activities_ex = environment.caption_dyn_activities_ex;

  // ---
  // we need the following information
  @Input() exhibitor_id: string = '33'; // exhibitor_id
  @Input() userid: string = '1242'; // user_id
  @Input() access_token: string = '';

  // --

  version: string | null = environment.version;
  error: string | undefined;
  isLoading = false;

  // firststart
  colLarge = 5;
  colXLarge = 5;
  showWelcome: boolean = true;
  myGroup: string = '';

  firstExhibitorForm: FormGroup;
  submitted = false;

  // exhibitor firststart data
  imageStorage = environment.eplusSystem + '/_fileDB/images/exhibitor/';
  _profile_image = '';
  _logo = '';
  _bild1 = '';
  _bild2 = '';
  _bild3 = '';

  isLoginClicked = false;
  isRegisterNewClicked = false;
  isForgetClicked = false;

  @ViewChild('myPond') myPond: any;

  feedback: any;

  pondOptions = {
    class: 'my-filepond',
    credits: false,
    multiple: false,
    allowFileTypeValidation: true,
    acceptedFileTypes: ['image/png', 'image/jpeg'],
    labelFileTypeNotAllowed: 'File of invalid type: only JPG, PNG',
    fileValidateTypeLabelExpectedTypesMap: { 'image/jpeg': '.jpg', 'image/png': '.png' },
    allowImageValidateSize: true,
    imageValidateSizeMinWidth: 150,
    imageValidateSizeMinHeight: 150,
    allowImageResize: true,
    imageResizeTargetWidth: 2400,
    imageResizeTargetHeight: 1600,
    imageResizeMode: 'contain',
    imageResizeUpscale: true,
    imageCropAspectRatio: 1,
    labelIdle: 'déposer le fichier ici ou <a style="text-decoration:underline;">parcourir</a> ...',
    // acceptedFileTypes: ['image/jpeg, image/png'],
    server: {
      process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
        // fieldName is the name of the input field
        // file is the actual file object to send
        //debugger;
        //console.table(fieldName);
        const formData = new FormData();
        formData.append(fieldName, file, file.name);
        formData.append('filepond', file.name);

        formData.append('uid', '' + this.userid);
        formData.append('fid', '' + this.exhibitor_id);
        formData.append('role', '2'); // exhibitor == 2
        formData.append('imgtype', '' + fieldName);

        const request = new XMLHttpRequest();
        request.open('POST', environment.serverUrl + '/filepond/process');

        // set `Content-Type` header
        //request.setRequestHeader('Content-Type', 'application/json');
        request.setRequestHeader('Access-Control-Allow-Origin', '*');
        //xhr.setRequestHeader('Accept', "application/json");
        request.setRequestHeader('Access-Control-Allow-Headers', '*');

        // token
        request.setRequestHeader('Authorization', `Bearer ${this.access_token}`);

        // Should call the progress method to update the progress to 100% before calling load
        // Setting computable to false switches the loading indicator to infinite mode
        request.upload.onprogress = (e) => {
          progress(e.lengthComputable, e.loaded, e.total);
        };

        // Should call the load method when done and pass the returned server file id
        // this server file id is then used later on when reverting or restoring a file
        // so your server knows which file to return without exposing that info to the client
        request.onload = function () {
          if (request.status >= 200 && request.status < 300) {
            // the load method accepts either a string (id) or an object
            load(request.responseText);
          } else {
            // Can call the error method if something is wrong, should exit after
            error('oh no');
          }
        };

        request.send(formData);

        // Should expose an abort method so the request can be cancelled
        return {
          abort: () => {
            // This function is entered if the user has tapped the cancel button
            request.abort();

            // Let FilePond know the request has been cancelled
            abort();
          },
        };
      },
      revert: 'filepond/revert',
      restore: 'filepond/restore?id=',
      fetch: 'filepond/fetch?data=',
      load: 'filepond/load',
    },
  };

  pondFiles = [
    // 'index.html'
  ];

  // acitivities --> exhibitor
  activities_en: any = [];
  activities_fr: any = [];

  EVENT_CATEGORIES: string[] = environment.EVENT_CATEGORIES;

  // FORM config
  showTerms = environment.terms_check;
  showDSGVO = environment.terms_check;
  showStorno = environment.terms_check;
  showMedia = environment.show_media_info;
  linkTerms = environment.linkTerms;
  linkDSGVO = environment.linkDSGVO;
  linkStorno = environment.linkStorno;
  linkMedia = environment.linkMedia;

  showDays = false; // show the day selection info
  _day1 = environment.day_1;
  _day2 = environment.day_2;
  //_hotel_info = environment.hotel_info;

  // firststart DATA
  fsShowFiles = false;

  constructor(
    private formBuilder: FormBuilder,
    private _snackBar: MatSnackBar,
    //private journalService: JournalService,
    private exhibitorService: ExhibitorService
  ) {
    ////// -------------------------- firststart
    this.activities_en[0] = 'Accomodation';
    this.activities_en[1] = 'Barging';
    this.activities_en[2] = 'Carriers';
    this.activities_en[3] = 'Cultural Sites';
    this.activities_en[4] = 'Excursions / Entertainment';
    this.activities_en[5] = 'Incoming services';
    this.activities_en[6] = 'Leisure or entertainment park';
    this.activities_en[7] = 'Restaurant';
    this.activities_en[8] = 'Ski';
    this.activities_en[9] = 'Tourist office';
    this.activities_en[10] = 'Other';
    this.activities_en[11] = 'Shopping';
    this.activities_en[12] = 'Luxury offers';
    this.activities_en[13] = 'MICE Services';
    this.activities_fr[0] = 'Hébergement';
    this.activities_fr[1] = 'Location fluviale';
    this.activities_fr[2] = 'Transporteur';
    this.activities_fr[3] = 'Sites culturels';
    this.activities_fr[4] = 'Excursion, guide';
    this.activities_fr[5] = 'Agence réceptive';
    this.activities_fr[6] = "Parc d'attraction ou de loisirs";
    this.activities_fr[7] = 'Restaurant ';
    this.activities_fr[8] = 'Ski';
    this.activities_fr[9] = 'Office du Tourisme';
    this.activities_fr[10] = 'Autre';
    this.activities_fr[11] = 'Shopping';
    this.activities_fr[12] = 'Luxury offers';
    this.activities_fr[13] = 'MICE Services';
    // dies sind jetzt dynamische felder --> nicht mehr ergänzen

    // load and init the form
    this.firstExhibitorForm = this.formBuilder.group({
      uid: this.userid,
      fid: this.exhibitor_id,
      role: 2, // exhibitor == 2

      //storno_accepted: [true, Validators.requiredTrue],
      //dsgvo_accepted: [true, Validators.requiredTrue],
      agb_accepted: [false, this._terms_check ? Validators.requiredTrue : []],

      taxid_uid: ['', this._taxid_required ? Validators.required : []],
      special_wishes: [''],

      //profile_image: [''], // via filepond solved

      //organisation: ['', Validators.required],
      //name: ['', Validators.required],
      //vorname: ['', Validators.required],
      //funktion: ['', Validators.required],
      adresse: ['', Validators.required],
      plz: ['', Validators.required],
      stadt: ['', Validators.required],
      telefon: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      web: ['', Validators.required],

      category: ['', Validators.required],

      beschreibung: ['', Validators.required],
      info1: ['', Validators.required], // neuigkeiten

      // region
      regionsGroup: new FormGroup(
        {
          auvergne: new FormControl(false),
          bourgogne: new FormControl(false),
          bretagne: new FormControl(false),
          centreloire: new FormControl(false),
          corse: new FormControl(false),
          champagne: new FormControl(false),
          nordcalais: new FormControl(false),
          normandie: new FormControl(false),
          aquitaine: new FormControl(false),
          languedoc: new FormControl(false),
          outremer: new FormControl(false),
          paris: new FormControl(false),
          paysloire: new FormControl(false),
          picardie: new FormControl(false),
          paca: new FormControl(false),
          multiregions: new FormControl(false),
        },
        requireAtLeastOneCheckboxValidator(1)
      ),

      // ---- all these are now used for the dyn fields
      //alsace: new FormControl(false),
      //limousin: new FormControl(false),
      //lorraine: new FormControl(false),
      //midipyrenes: new FormControl(false),
      //poitoucharentes: new FormControl(false),
      //rhonealpes: new FormControl(false),
      //riveria: new FormControl(false),
      //monaco: new FormControl(false),
      //thaiti: new FormControl(false),
      //luxury: new FormControl(false),

      // ---------------------------------
      // this one is still not used !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
      //mice: new FormControl(false),

      // activite
      activitiesGroup: new FormGroup(
        {
          agencereceptive: new FormControl(false),
          centralereservation: new FormControl(false),
          destination: new FormControl(false),
          excursion: new FormControl(false),
          groupement: new FormControl(false),
          locationfluviale: new FormControl(false),
          musee: new FormControl(false),
          transporteur: new FormControl(false),
          autre: new FormControl(false),
          offreski: new FormControl(false),
          ski_station: new FormControl(false),
          ski_rental: new FormControl(false),
          residencetourisme: new FormControl(false),
          restaurant: new FormControl(false),
        },
        requireAtLeastOneCheckboxValidator(1)
      ),

      // bilder
      //bild1: ['', Validators.required],
      //bild2: ['', Validators.required],
      //bild3: ['', Validators.required],
      //logo: ['', Validators.required],

      // tage teilnahme
      chkcity1: ['true'],
      chkcity2: ['true'],
      //chkcity3: ['', Validators.required],
      //chkcity4: ['', Validators.required],
      //chkcity5: ['', Validators.required],

      //room: ['', Validators.required],
    });

    // add the dyn fields
    const regionsKeys = this.getKeysRegions();
    const activitiesKeys = this.getKeysActivities();

    regionsKeys.forEach((key, index) => {
      if (this.show_dyn_regions_ex[index]) {
        (this.firstExhibitorForm.get('regionsGroup') as FormGroup).addControl(key, new FormControl(false));
      }
    });

    activitiesKeys.forEach((key, index) => {
      if (this.show_dyn_activities_ex[index]) {
        (this.firstExhibitorForm.get('activitiesGroup') as FormGroup).addControl(key, new FormControl(false));
      }
    });
  }

  ngOnInit(): void {
    console.log('init profile exhibitor');

    // get the data for the exhibitor

    console.log('fetch: ' + this.exhibitor_id);

    // get the visitor with the given ID
    this.exhibitorService.getExhibitorById(+this.exhibitor_id).subscribe((exhibitor: ExhibitorSingle) => {
      console.log('exhibitor from DB');
      console.log(exhibitor);

      // set the profile image
      this._profile_image = exhibitor.data.profile_image;
      this._logo = exhibitor.data.logo;
      this._bild1 = exhibitor.data.bild1;
      this._bild2 = exhibitor.data.bild2;
      this._bild3 = exhibitor.data.bild3;

      // const access_token = this.authQuery.access_token;

      this.firstExhibitorForm.patchValue({
        uid: this.userid,
        fid: this.exhibitor_id,
        role: 2, // exhibitor == 2
        //firststart_done: this.authQuery.firststart_done,

        // judical things
        //storno_accepted: exhibitor.data.storno_accepted == -1 ? true : false,
        //dsgvo_accepted: exhibitor.data.dsgvo_accepted == -1 ? true : false,
        agb_accepted: exhibitor.data.agb_accepted == -1 ? true : false,

        // special wishes
        special_wishes: exhibitor.data.special_wishes,
        taxid_uid: exhibitor.data.uid,

        // admin register new fields
        company: exhibitor.data.organisation,
        firstname: exhibitor.data.vorname,
        lastname: exhibitor.data.name,
        position: exhibitor.data.funktion,
        //country: exhibitor.data.country,

        //
        adresse: exhibitor.data.adresse,
        plz: exhibitor.data.plz,
        stadt: exhibitor.data.stadt,
        telefon: exhibitor.data.telefon,
        email: exhibitor.data.email,
        web: exhibitor.data.web,

        category: exhibitor.data.category,
        beschreibung: exhibitor.data.beschreibung,
        info1: exhibitor.data.info1,

        //Days
        chkcity1: exhibitor.data.chkcity1 == 1 ? true : false,
        chkcity2: exhibitor.data.chkcity2 == 1 ? true : false,
      });

      this.firstExhibitorForm.controls['regionsGroup'].patchValue({
        auvergne: exhibitor.data.auvergne == -1 ? true : false,
        bourgogne: exhibitor.data.bourgogne == -1 ? true : false,
        bretagne: exhibitor.data.bretagne == -1 ? true : false,
        centreloire: exhibitor.data.centreloire == -1 ? true : false,
        corse: exhibitor.data.corse == -1 ? true : false,
        champagne: exhibitor.data.champagne == -1 ? true : false,
        nordcalais: exhibitor.data.nordcalais == -1 ? true : false,
        normandie: exhibitor.data.normandie == -1 ? true : false,
        aquitaine: exhibitor.data.aquitaine == -1 ? true : false,
        languedoc: exhibitor.data.languedoc == -1 ? true : false,
        outremer: exhibitor.data.outremer == -1 ? true : false,
        paris: exhibitor.data.paris == -1 ? true : false,
        paysloire: exhibitor.data.paysloire == -1 ? true : false,
        picardie: exhibitor.data.picardie == -1 ? true : false,
        paca: exhibitor.data.paca == -1 ? true : false,
        multiregions: exhibitor.data.multiregions == -1 ? true : false,
      });

      this.firstExhibitorForm.controls['activitiesGroup'].patchValue({
        agencereceptive: exhibitor.data.agencereceptive == -1 ? true : false,
        centralereservation: exhibitor.data.centralereservation == -1 ? true : false,
        destination: exhibitor.data.destination == -1 ? true : false,
        excursion: exhibitor.data.excursion == -1 ? true : false,
        groupement: exhibitor.data.groupement == -1 ? true : false,
        locationfluviale: exhibitor.data.locationfluviale == -1 ? true : false,
        musee: exhibitor.data.musee == -1 ? true : false,
        transporteur: exhibitor.data.transporteur == -1 ? true : false,
        autre: exhibitor.data.autre == -1 ? true : false,
        offreski: exhibitor.data.offreski == -1 ? true : false,
        ski_station: exhibitor.data.ski_station == -1 ? true : false,
        ski_rental: exhibitor.data.ski_rental == -1 ? true : false,
        residencetourisme: exhibitor.data.residencetourisme == -1 ? true : false,
        restaurant: exhibitor.data.restaurant == -1 ? true : false,
      });

      //
      // ------------------ load the dyn fields
      //
      const regionsKeys = this.getKeysRegions();
      const activitiesKeys = this.getKeysActivities();

      /*
      regionsKeys.forEach((key, index) => {
        if (this.show_dyn_regions_ex[index]) {
          (this.firstExhibitorForm.get('regionsGroup') as FormGroup).addControl(key, new FormControl(false));
        }
      });

      activitiesKeys.forEach((key, index) => {
        if (this.show_dyn_activities_ex[index]) {
          (this.firstExhibitorForm.get('activitiesGroup') as FormGroup).addControl(key, new FormControl(false));
        }
      });
      */
      console.log('update dyn fields');

      // and now we aldo have to patch the values of the dynamic form fields
      // Patch regions group values
      const regionsGroupValues = {};
      regionsKeys.forEach((key) => {
        regionsGroupValues[key] = exhibitor.data[key] === '-1 ' ? true : false;
      });
      (this.firstExhibitorForm.get('regionsGroup') as FormGroup).patchValue(regionsGroupValues);

      // Patch activities group values
      const activitiesGroupValues = {};
      activitiesKeys.forEach((key) => {
        activitiesGroupValues[key] = exhibitor.data[key] === '-1' ? true : false;
      });
      (this.firstExhibitorForm.get('activitiesGroup') as FormGroup).patchValue(activitiesGroupValues);
    });
  }

  //
  //
  // -------------------------------------- update profile
  //

  selectedIndex: number = 0;

  setIndex(event) {
    this.selectedIndex = event.selectedIndex;
  }
  triggerClick() {
    //console.log(`Selected tab index: ${this.selectedIndex}`);
    if (this.selectedIndex == 4) {
      // wenn es sich um das file-drag-and-drop handelt
      // dann soll beim mouse-move der dialog stehen bleiben
      this.fsShowFiles = true;
    } else {
      this.fsShowFiles = false;
    }
  }

  /**
   *  --------------- dynamic field keys
   *
   */
  getKeysRegions() {
    return Object.keys(this.caption_dyn_regions_ex);
  }

  getKeysActivities() {
    return Object.keys(this.caption_dyn_activities_ex);
  }

  /**
   *
   *
   */
  saveValues() {
    console.log('submit');
    this.submitted = true;

    // stop here if form is invalid
    if (this.firstExhibitorForm.invalid) {
      return;
      // we do not care if it is invalid
      // because we do a cache save
      //
    } else {
    }

    // display form values on success
    this.submitted = true;
    if (this.firstExhibitorForm.valid) {
      //console.table(this.firstExhibitorForm.value);
    }

    // set the form params
    const userid = this.userid;
    const fid = this.exhibitor_id;
    const role = 2; // exhibitor == 2

    //this.firstExhibitorForm.controls['access_token'].setValue(access_token);
    this.firstExhibitorForm.controls['uid'].setValue(userid);
    this.firstExhibitorForm.controls['fid'].setValue(fid);
    this.firstExhibitorForm.controls['role'].setValue(role);

    this.exhibitorService
      .updateExhibitorData(this.firstExhibitorForm.value, +this.exhibitor_id)
      .pipe(
        finalize(() => {
          this.firstExhibitorForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data) => {
          if (data.status === 401) {
            console.log('login error');
            this._snackBar.open('Error: Something went wrong.', 'Close', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 3000,
              // panelClass: ['mat-toolbar', 'mat-primary'],
            });
          } else {
            //log.debug(`${data.name} successfully firststart done`);
            //this.journalService.log('Exhibitor Profile erfolgreich gespeichert.');

            this._snackBar.open('Profile Data was saved successfully.', 'Close', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 3000,
              // panelClass: ['mat-toolbar', 'mat-primary'],
            });
          }
        },
        (error) => {
          //log.debug(`Login error: ${error}`);
          this.error = error;
        }
      );
  }

  //
  // -----------------------------------------------------
  //

  pondHandleInit() {
    console.log('FilePond has initialised', this.myPond);
  }

  pondHandleAddFile(event: any) {
    console.log('A file was added', event);
  }

  pondHandleActivateFile(event: any) {
    console.log('A file was activated', event);
  }

  getCountryName(country: string) {
    switch (country) {
      case 'at':
        return 'Austria';
      case 'ba':
        return 'Bosnia-Herzegovina';
      case 'bg':
        return 'Bulgaria';
      case 'hr':
        return 'Croatia';
      case 'cz':
        return 'Czech Republic';
      case 'fr':
        return 'France';
      case 'de':
        return 'Germany';
      case 'hu':
        return 'Hungary';
      case 'xk':
        return 'Kosovo';
      case 'mk':
        return 'Macedonia';
      case 'mne':
        return 'Montenegro';
      case 'pl':
        return 'Poland';
      case 'ro':
        return 'Romania';
      case 'xs':
        return 'Serbia';
      case 'sk':
        return 'Slovakia';
      case 'si':
        return 'Slovenia';
      case 'ch':
        return 'Switzerland';
      default:
        return '';
    }
  }
}
