import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

//platformBrowserDynamic().bootstrapModule(AppModule)
//  .catch(err => console.error(err));

function loadConfig(client: string): Promise<void> {
  return new Promise((resolve, reject) => {
    /*
    fetch(`./assets/events/config-${client}.json?_=${new Date().getTime()}`, {
      headers: {
        'Cache-Control': 'no-cache',
      },
    })
      .then((response) => response.json())
      .then((config) => {
        console.log('Configuration loaded:', config);
        // Apply the configuration to your environment
        Object.assign(environment, config);
        console.log('xxxxxxxxxxxxxx load config xxxxxxxxxxxxxxxxxxxx');
        console.log(environment);
        resolve();
      })
      .catch((error) => {
        console.error('Failed to load configuration:', error);
        reject(error);
      });
    */
    /* -- mario -- 2025
    import(`./assets/events/config-${client}.ts`)
      .then((module) => {
        Object.assign(environment, module.clientConfig);
        console.log('xxxxxxxxxxxxxx load config xxxxxxxxxxxxxxxxxxxx');
        console.log(environment);
        resolve();
      })
      .catch((err) => {
        console.error(`Error loading configuration for client ${client}:`, err);
        reject(err);
      });
    */
  });
}

const client = environment.client; // Assume this is set correctly in the environment

// mario -> do not load the config anymore
// 10.3.25 -> we do it with environment alone ! easier to handle
/*
loadConfig(client).then(() => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
});
-- end changes */
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

/*
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { ConfigService } from './app/services/config.service';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

function loadConfig(): Promise<void> {
  const injector = platformBrowserDynamic().injector;
  const http = injector.get(HttpClient);
  const client = environment.client;

  return firstValueFrom(http.get(`assets/events/config-${client}.json`)).then((config: any) => {
    Object.assign(environment, config);
  });
}

fetch('assets/config-client.json')
  .then(response => response.json())
  .then(clientConfig => {
    environment.client = clientConfig.client;

    return loadConfig();
  })
  .then(() => {
    platformBrowserDynamic().bootstrapModule(AppModule)
      .catch(err => console.error(err));
  });
*/
