import { Component, Output, EventEmitter, SimpleChanges, OnInit, OnChanges, HostListener, ViewChild } from '@angular/core';
import { ViewEncapsulation, ElementRef, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormArray, AbstractControl, FormControl } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { tap, map } from 'rxjs/operators';
import { retry, catchError } from 'rxjs/operators';

import { MatSnackBar } from '@angular/material/snack-bar';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { DeviceDetectorService } from 'ngx-device-detector';

import * as tus from 'tus-js-client';

import { environment } from '../../environments/environment';

import { Logger } from '@core';

import { AuthQuery } from '@app/auth/auth.query';

import { JournalService, MailService, Mail } from '@core';
import { CookieService } from 'ngx-cookie-service';

import { AuthDataService } from '@app/auth/auth-data.service';
import { MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS_FACTORY } from '@angular/material/progress-spinner';

import { requireAtLeastOneCheckboxValidator } from '../@shared/validation/require-checkboxes-to-be-checked.validator';

import { VisitorService, Visitor, VisitorSingle } from '../services/visitor.service';

const log = new Logger('Login');

@Component({
  templateUrl: './firststart.component.html',
  styleUrls: ['./firststart.component.scss'],
})
export class FirststartComponent implements OnInit {
  //
  // - define if special pages should be per country
  //

  _client = environment.client;

  _veranstaltungsland = environment.veranstaltungs_land; // country code
  noCountrySelection = !environment.county_selection; // true or false

  _show_accounting_info = environment.show_accounting_info;

  _terms_check = environment.terms_check;
  _taxid_required = environment.taxid_required;

  // -----------
  //

  visitor: Visitor;
  @Input() visitor_id;

  @Input() _uid;
  @Input() _fid;
  @Input() _role;
  @Input() _country;
  @Input() _isAdmin;

  _accounting_link: string;

  @ViewChild('myPond') myPond: any;
  pondOptions = {
    class: 'my-filepond',
    credits: false,
    multiple: false,
    allowFileTypeValidation: true,
    acceptedFileTypes: ['image/png', 'image/jpeg'],
    labelFileTypeNotAllowed: 'File of invalid type: only JPG, PNG',
    fileValidateTypeLabelExpectedTypesMap: { 'image/jpeg': '.jpg', 'image/png': '.png' },
    allowImageValidateSize: true,
    imageValidateSizeMinWidth: 150,
    imageValidateSizeMinHeight: 150,
    allowImageResize: true,
    imageResizeTargetWidth: 2400,
    imageResizeTargetHeight: 1600,
    imageResizeMode: 'contain',
    imageResizeUpscale: true,
    imageCropAspectRatio: 1,
    labelIdle: 'Drop files here or <a style="text-decoration:underline;">Browse</a> ...',
    // acceptedFileTypes: ['image/jpeg, image/png'],
    server: {
      process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
        // fieldName is the name of the input field
        // file is the actual file object to send
        //debugger;
        //console.table(fieldName);
        const formData = new FormData();
        formData.append(fieldName, file, file.name);
        formData.append('filepond', file.name);

        formData.append('uid', '' + this._uid);
        formData.append('fid', '' + this._fid);
        formData.append('role', '' + this._role);
        formData.append('imgtype', '' + fieldName);

        const request = new XMLHttpRequest();
        request.open('POST', environment.serverUrl + '/filepond/process');

        // set `Content-Type` header
        //request.setRequestHeader('Content-Type', 'application/json');
        request.setRequestHeader('Access-Control-Allow-Origin', '*');
        //xhr.setRequestHeader('Accept', "application/json");
        request.setRequestHeader('Access-Control-Allow-Headers', '*');

        // token
        request.setRequestHeader('Authorization', `Bearer ${this.authQuery.access_token}`);

        // Should call the progress method to update the progress to 100% before calling load
        // Setting computable to false switches the loading indicator to infinite mode
        request.upload.onprogress = (e) => {
          progress(e.lengthComputable, e.loaded, e.total);
        };

        // Should call the load method when done and pass the returned server file id
        // this server file id is then used later on when reverting or restoring a file
        // so your server knows which file to return without exposing that info to the client
        request.onload = function () {
          if (request.status >= 200 && request.status < 300) {
            // the load method accepts either a string (id) or an object
            load(request.responseText);
          } else {
            // Can call the error method if something is wrong, should exit after
            error('oh no');
          }
        };

        request.send(formData);

        // Should expose an abort method so the request can be cancelled
        return {
          abort: () => {
            // This function is entered if the user has tapped the cancel button
            request.abort();

            // Let FilePond know the request has been cancelled
            abort();
          },
        };
      },
      revert: 'filepond/revert',
      restore: 'filepond/restore?id=',
      fetch: 'filepond/fetch?data=',
      load: 'filepond/load',
    },
  };

  pondFiles = [
    // 'index.html'
  ];

  // request vars
  version: string | null = environment.version;
  error: string | undefined;
  isLoading = false;

  firstVisitorForm: FormGroup;
  submitted = false;

  // acitivities --> exhibitor
  activities_en: any = [];
  activities_fr: any = [];

  // FORM config
  showTerms = environment.terms_check;
  showDSGVO = environment.terms_check;
  showStorno = environment.terms_check;
  showMedia = environment.show_media_info;
  linkTerms = environment.linkTerms;
  linkDSGVO = environment.linkDSGVO;
  linkStorno = environment.linkStorno;
  linkMedia = environment.linkMedia;

  termsListA = environment.termsListA; // ['de']; //['at', 'sk', 'si'];
  termsListB = environment.termsListB; // ['at', 'ba', 'bg', 'hr', 'cz', 'fr', 'hu', 'xk', 'mk', 'mne', 'pl', 'ro', 'xs', 'sk', 'si', 'ch']; //['cz', 'hu'];

  // dynamic fields
  show_dyn_identity = environment.show_dyn_identity;
  caption_dyn_identity = environment.caption_dyn_identity;

  show_dyn_programming = environment.show_dyn_programming;
  caption_dyn_programming = environment.caption_dyn_programming;

  show_dyn_products = environment.show_dyn_products;
  caption_dyn_products = environment.caption_dyn_products;

  show_dyn_regions = environment.show_dyn_regions;
  caption_dyn_regions = environment.caption_dyn_regions;

  show_dyn_productsreq = environment.show_dyn_productsreq;
  caption_dyn_productsreq = environment.caption_dyn_productsreq;

  // dyn content
  __txt_Event_Name = environment.txt_EventName;
  __txt_Event_City = environment.txt_Event_City;
  __hotel_parking = environment.hotel_parking;

  showDays = true; // show the day selection info
  _day1 = environment.day_1;
  _day2 = environment.day_2;

  // environment
  imageStorage = environment.eplusSystem + '/_fileDB/images/visitor/';

  _eventYear = environment.event_year;
  _veranstalter = environment.veranstalter;
  _hotel_info = environment.hotel_info;
  __txt_Hotel_Night = environment.txt_Hotel_Night;
  _event_space = environment.event_space;
  _event_date = environment.event_date;
  _book_night = environment.book_night;
  _cancel_date = environment.cancel_date;
  // ---

  _profile_image = '';

  //_country: string = '';

  constructor(
    private element: ElementRef,
    private _fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private authService: AuthDataService,
    private authQuery: AuthQuery,
    private router: Router,
    private _activeRoute: ActivatedRoute,
    private journalService: JournalService,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
    private visitorService: VisitorService
  ) {
    console.log('firststart-constructor');

    //this._country = this.authQuery.country;

    /*
    name;
    email;
    firststart;
    company;
    firststart_done;
    profile_image;
    role;
    version;
    active;
    isapproved;
    */

    // load and init the form
    this.firstVisitorForm = this._fb.group({
      //uid: this.authQuery.userid,
      //fid: this.authQuery.fid,
      //role: this.authQuery.role,
      uid: 0,
      fid: 0,
      role: 3,
      firststart_done: this.authQuery.firststart_done,

      storno_accepted: [false, this._terms_check ? Validators.requiredTrue : []],
      dsgvo_accepted: [false, this._terms_check ? Validators.requiredTrue : []],
      agb_accepted: [false, this._terms_check ? Validators.requiredTrue : []],
      media_accepted: [false, this._terms_check ? Validators.requiredTrue : []],

      //storno_accepted: [false], //, Validators.requiredTrue
      //dsgvo_accepted: [false], //, Validators.requiredTrue
      //agb_accepted: [false], // , Validators.requiredTrue
      //media_accepted: [false], // , Validators.requiredTrue

      special_wishes: [],

      //profile_image: ['', Validators.required],

      // country data
      garage_day1: [],
      garage_day2: [],
      lunch_day1: [],
      lunch_day2: [],
      attendance_event: [],
      hotel_stay_night: [],
      transfer_arrival: [],
      transfer_return: [],
      fransfer_from: [],
      flight_booked: [],
      transfer_type: [],

      //country: ['', Validators.required],
      //company: ['', Validators.required],
      //lastname: ['', Validators.required],
      //firstname: ['', Validators.required],
      //position: ['', Validators.required],
      //title: ['', Validators.required],
      //emailincharge: ['', [Validators.required, Validators.email]],

      company: ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      position: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      country: ['', Validators.required],

      adress: ['', Validators.required],
      areacode: ['', Validators.required], // zip code
      city: ['', Validators.required],
      //countrycode: ['', Validators.required],
      companyphone: ['', Validators.required],
      companymail: ['', [Validators.required, Validators.email]],
      website: ['', Validators.required],
      icphone: ['', Validators.required],

      taxid_uid: ['', this._taxid_required ? Validators.required : []],

      //category: ['', Validators.required], ?? nicht befüllt

      // Your Company's Identity
      compIdentityGroup: new FormGroup(
        {
          generalisttour: new FormControl(false),
          specialisttour: new FormControl(false),
          tourownretailing: new FormControl(false),
          tourretailingtravelagencies: new FormControl(false),
          touroperatormember: new FormControl(false),
          travel: new FormControl(false),
          incentive: new FormControl(false),
          inernationalincoming: new FormControl(false),
          coach: new FormControl(false),
          //carrental: new FormControl(false),
          hotelbookingcallcenter: new FormControl(false),
          //aircarrier: new FormControl(false),
          //seacarrier: new FormControl(false),
          //roadcarrier: new FormControl(false),
          //railcarrier: new FormControl(false),
        },
        requireAtLeastOneCheckboxValidator(1)
      ),

      // Number of employees in the company
      numberemployees: ['1'],
      // Volume of travellers " Groups " to France per year
      volumegroups: ['1'],
      // Volume of " Individuals " travellers to France per year
      volumeindividuals: ['1'],
      // Number of brochures per year with France featured
      numberbrochures: ['1'],
      // Brochure is only online
      palsace: [''], // Brochure is only online
      // Can we book on line on your website some products featuring France
      onlinebooking: [''],

      //info1: [''],  //
      //info2: [''],
      //zusatz1: [''],
      //zusatz2: [''],
      //zusatz3: [''],

      //Days
      chkcity1: ['true'],
      chkcity2: ['true'],
      //chkcity3: [''],
      //chkcity4: [''],
      //chkcity5: [''],

      // fax: ['', Validators.required],

      // Offer
      // Programming
      programmingGroup: new FormGroup(
        {
          pauvergne: new FormControl(false),
          pbrittany: new FormControl(false),
          pburgundy: new FormControl(false),
          pcorsica: new FormControl(false),
          pchampagne: new FormControl(false),
          ploirevalley: new FormControl(false),
          pnordpasdecalais: new FormControl(false),
          pnormandy: new FormControl(false),
          plimousin: new FormControl(false),
          pfranche: new FormControl(false),
          plancuedoc: new FormControl(false),
          poverseas: new FormControl(false),
          pparis: new FormControl(false),
          pwesternloire: new FormControl(false),
          pprovence: new FormControl(false),
          pall: new FormControl(false),
        },
        requireAtLeastOneCheckboxValidator(1)
      ),

      // Products offered
      productsGroup: new FormGroup(
        {
          //pobusiness: new FormControl(false),
          pobeach: new FormControl(false),
          pocruises: new FormControl(false),
          pobarging: new FormControl(false),
          pogolf: new FormControl(false),
          //poyouth: new FormControl(false),
          //posummer: new FormControl(false),
          ponature: new FormControl(false),
          //poreligious: new FormControl(false),
          powinter: new FormControl(false),
          pocultural: new FormControl(false),
          pogastronomy: new FormControl(false),
          pourban: new FormControl(false),
          poactive: new FormControl(false),
          pochampagne: new FormControl(false),
          pofitness: new FormControl(false),
        },
        requireAtLeastOneCheckboxValidator(1)
      ),

      // Your Request
      // Sought regions
      soughtRegionsGroup: new FormGroup(
        {
          sauvergne: new FormControl(false),
          sbrittany: new FormControl(false),
          sburgundy: new FormControl(false),
          scorsica: new FormControl(false),
          schampagne: new FormControl(false),
          sloire: new FormControl(false),
          snordpasdecalais: new FormControl(false),
          snormandy: new FormControl(false),
          slimousin: new FormControl(false),
          sfranche: new FormControl(false),
          slanguedoc: new FormControl(false),
          soverseas: new FormControl(false),
          sparis: new FormControl(false),
          swesternloire: new FormControl(false),
          sprovence: new FormControl(false),
          sall: new FormControl(false),
        },
        requireAtLeastOneCheckboxValidator(1)
      ),

      // Kind of products requested
      productsRequested: new FormGroup(
        {
          khotelgroup: new FormControl(false),
          //k2starhotel: new FormControl(false),
          //k3starhotel: new FormControl(false),
          //k4starhotel: new FormControl(false),
          palacehotels: new FormControl(false),
          kvacation: new FormControl(false),
          kholidayclub: new FormControl(false),
          kbandb: new FormControl(false),
          krestaurant: new FormControl(false),
          kincomingagency: new FormControl(false),
          kcongress: new FormControl(false),
          gsightseeing: new FormControl(false),
          kairtrain: new FormControl(false),
          kcoach: new FormControl(false),
          kbarging: new FormControl(false),
          //kleisure: new FormControl(false),
          kmuseum: new FormControl(false),
          //kshow: new FormControl(false),
          kmall: new FormControl(false),
          ktouristoffice: new FormControl(false),
          danydietryallergies: new FormControl(''), // others
        },
        requireAtLeastOneCheckboxValidator(1)
      ),

      //spicardy: [''],
      //spoitou: [''],
      //srhonealps: [''],
      //sfrenchriviera: [''],
      //paquitaine: [''],
      //plorraine: [''],
      //pmidi: [''],
      //ppicardy: [''],
      //ppoitou: [''],
      //prhonealps: [''],
      //pfrenchriviera: [''],
      //salsace: [''],
      //saquitaine: [''],
      //slorraine: [''],
      //smidi: [''],
      //allergies: [''],
    });

    // ------------------------------
    // add the dynmic fields
    // build the dynamic checkboxes for each topic
    const identityKeys = this.getKeysIdentity();
    const programmingKeys = this.getKeysProgramming();
    const productsKeys = this.getKeysProducts();
    const regionsKeys = this.getKeysRegions();
    const productsReqKeys = this.getKeysProductsReq();

    identityKeys.forEach((key, index) => {
      if (this.show_dyn_identity[index]) {
        (this.firstVisitorForm.get('compIdentityGroup') as FormGroup).addControl(key, new FormControl(false));
      }
    });

    programmingKeys.forEach((key, index) => {
      if (this.show_dyn_programming[index]) {
        (this.firstVisitorForm.get('programmingGroup') as FormGroup).addControl(key, new FormControl(false));
      }
    });

    productsKeys.forEach((key, index) => {
      if (this.show_dyn_products[index]) {
        (this.firstVisitorForm.get('productsGroup') as FormGroup).addControl(key, new FormControl(false));
      }
    });

    regionsKeys.forEach((key, index) => {
      if (this.show_dyn_regions[index]) {
        (this.firstVisitorForm.get('soughtRegionsGroup') as FormGroup).addControl(key, new FormControl(false));
      }
    });

    productsReqKeys.forEach((key, index) => {
      if (this.show_dyn_productsreq[index]) {
        (this.firstVisitorForm.get('productsRequested') as FormGroup).addControl(key, new FormControl(false));
      }
    });
  }

  ngOnInit(): void {
    console.log('firststart-oninit');

    this._activeRoute.paramMap.subscribe((paramMap) => {
      this.visitor_id = paramMap.get('id');

      this._uid = paramMap.get('_uid');
      this._fid = paramMap.get('_fid');
      this._role = paramMap.get('_role');
      this._country = paramMap.get('_country');
      this._isAdmin = paramMap.get('ia');

      console.log('show params');
      console.log('uid ' + this._uid);
      console.log('fid ' + this._fid);
      console.log('role ' + this._role);
      console.log('country ' + this._country);

      // get the visitor with the given ID
      this.visitorService.getVisitorById(this.visitor_id).subscribe((visitor: VisitorSingle) => {
        console.log('visitor from DB');
        console.log(visitor);

        // set the profile image
        this._profile_image = visitor.data.profile_image;

        // set the accounting link
        this._accounting_link = environment.serverUrl + '/pdf-accinfo/' + this._fid + '?&akey=12987361235283447283261';

        this.firstVisitorForm.patchValue({
          uid: this._uid,
          fid: this._fid,
          role: this._role,
          //firststart_done: this.authQuery.firststart_done,

          // admin register new fields
          company: visitor.data.company,
          firstname: visitor.data.firstname,
          lastname: visitor.data.lastname,
          position: visitor.data.position,
          email: visitor.data.emailincharge,
          country: visitor.data.country,

          //
          adress: visitor.data.adress,
          areacode: visitor.data.areacode,
          city: visitor.data.city,
          companyphone: visitor.data.companyphone,
          companymail: visitor.data.companymail,
          website: visitor.data.website,
          icphone: visitor.data.icphone,
          taxid_uid: visitor.data.uid,

          // judical things
          storno_accepted: visitor.data.storno_accepted == -1 ? true : false,
          dsgvo_accepted: visitor.data.dsgvo_accepted == -1 ? true : false,
          agb_accepted: visitor.data.agb_accepted == -1 ? true : false,
          media_accepted: visitor.data.media_accepted == -1 ? true : false,

          // special wishes
          special_wishes: visitor.data.special_wishes,

          // country data
          garage_day1: visitor.data.garage_day1 == -1 ? true : false,
          garage_day2: visitor.data.garage_day2 == -1 ? true : false,
          lunch_day1: visitor.data.lunch_day1 == -1 ? true : false,
          lunch_day2: visitor.data.lunch_day2 == -1 ? true : false,
          attendance_event: visitor.data.attendance_event == -1 ? true : false,
          hotel_stay_night: visitor.data.hotel_stay_night == -1 ? true : false,
          transfer_arrival: visitor.data.transfer_arrival == -1 ? true : false,
          transfer_return: visitor.data.transfer_return == -1 ? true : false,
          fransfer_from: visitor.data.data1, // $data1
          flight_booked: visitor.data.flight_booked == -1 ? true : false,
          transfer_type: visitor.data.data2, // $data2

          // numbers
          numberemployees: visitor.data.numberemployees,
          volumegroups: visitor.data.volumegroups,
          volumeindividuals: visitor.data.volumeindividuals,
          numberbrochures: visitor.data.numberbrochures,
          //palsace: +visitor.data.palsace, // Brochure is only online
          //onlinebooking: +visitor.data.onlinebooking,
          palsace: visitor.data.palsace == -1 ? true : false,
          onlinebooking: visitor.data.onlinebooking == -1 ? true : false,

          //Days
          chkcity1: visitor.data.chkcity1 == 1 ? true : false,
          chkcity2: visitor.data.chkcity2 == 1 ? true : false,
        });

        this.firstVisitorForm.controls['compIdentityGroup'].patchValue({
          generalisttour: visitor.data.generalisttour == -1 ? true : false,
          specialisttour: visitor.data.specialisttour == -1 ? true : false,
          tourownretailing: visitor.data.tourownretailing == -1 ? true : false,
          tourretailingtravelagencies: visitor.data.tourretailingtravelagencies == -1 ? true : false,
          touroperatormember: visitor.data.touroperatormember == -1 ? true : false,
          travel: visitor.data.travel == -1 ? true : false,
          incentive: visitor.data.incentive == -1 ? true : false,
          inernationalincoming: visitor.data.inernationalincoming == -1 ? true : false,
          coach: visitor.data.coach == -1 ? true : false,
          //carrental: visitor.data.carrental == -1 ? true : false,
          hotelbookingcallcenter: visitor.data.hotelbookingcallcenter == -1 ? true : false,
          //aircarrier: visitor.data.aircarrier == -1 ? true : false,
          //seacarrier: visitor.data.seacarrier == -1 ? true : false,
          //roadcarrier: visitor.data.roadcarrier == -1 ? true : false,
          //railcarrier: visitor.data.railcarrier == -1 ? true : false,
        });

        this.firstVisitorForm.controls['programmingGroup'].patchValue({
          pauvergne: visitor.data.pauvergne == -1 ? true : false,
          pbrittany: visitor.data.pbrittany == -1 ? true : false,
          pburgundy: visitor.data.pburgundy == -1 ? true : false,
          pcorsica: visitor.data.pcorsica == -1 ? true : false,
          pchampagne: visitor.data.pchampagne == -1 ? true : false,
          ploirevalley: visitor.data.ploirevalley == -1 ? true : false,
          pnordpasdecalais: visitor.data.pnordpasdecalais == -1 ? true : false,
          pnormandy: visitor.data.pnormandy == -1 ? true : false,
          plimousin: visitor.data.plimousin == -1 ? true : false,
          pfranche: visitor.data.pfranche == -1 ? true : false,
          plancuedoc: visitor.data.plancuedoc == -1 ? true : false,
          poverseas: visitor.data.poverseas == -1 ? true : false,
          pparis: visitor.data.pparis == -1 ? true : false,
          pwesternloire: visitor.data.pwesternloire == -1 ? true : false,
          pprovence: visitor.data.pprovence == -1 ? true : false,
          pall: visitor.data.pall == -1 ? true : false,
        });

        // Products offered
        this.firstVisitorForm.controls['productsGroup'].patchValue({
          //pobusiness: visitor.data.pobusiness == -1 ? true : false,
          pobeach: visitor.data.pobeach == -1 ? true : false,
          pocruises: visitor.data.pocruises == -1 ? true : false,
          pobarging: visitor.data.pobarging == -1 ? true : false,
          pogolf: visitor.data.pogolf == -1 ? true : false,
          //poyouth: visitor.data.poyouth == -1 ? true : false,
          //posummer: visitor.data.posummer == -1 ? true : false,
          ponature: visitor.data.ponature == -1 ? true : false,
          //poreligious: visitor.data.poreligious == -1 ? true : false,
          powinter: visitor.data.powinter == -1 ? true : false,
          pocultural: visitor.data.pocultural == -1 ? true : false,
          pogastronomy: visitor.data.pogastronomy == -1 ? true : false,
          pourban: visitor.data.pourban == -1 ? true : false,
          poactive: visitor.data.poactive == -1 ? true : false,
          pochampagne: visitor.data.pochampagne == -1 ? true : false,
          pofitness: visitor.data.pofitness == -1 ? true : false,
        });

        // Your Request
        // Sought regions
        this.firstVisitorForm.controls['soughtRegionsGroup'].patchValue({
          sauvergne: visitor.data.sauvergne == -1 ? true : false,
          sbrittany: visitor.data.sbrittany == -1 ? true : false,
          sburgundy: visitor.data.sburgundy == -1 ? true : false,
          scorsica: visitor.data.scorsica == -1 ? true : false,
          schampagne: visitor.data.schampagne == -1 ? true : false,
          sloire: visitor.data.sloire == -1 ? true : false,
          snordpasdecalais: visitor.data.snordpasdecalais == -1 ? true : false,
          snormandy: visitor.data.snormandy == -1 ? true : false,
          slimousin: visitor.data.slimousin == -1 ? true : false,
          sfranche: visitor.data.sfranche == -1 ? true : false,
          slanguedoc: visitor.data.slanguedoc == -1 ? true : false,
          soverseas: visitor.data.soverseas == -1 ? true : false,
          sparis: visitor.data.sparis == -1 ? true : false,
          swesternloire: visitor.data.swesternloire == -1 ? true : false,
          sprovence: visitor.data.sprovence == -1 ? true : false,
          sall: visitor.data.sall == -1 ? true : false,
        });

        // Kind of products requested
        this.firstVisitorForm.controls['productsRequested'].patchValue({
          khotelgroup: visitor.data.khotelgroup == -1 ? true : false,
          //k2starhotel: visitor.data.k2starhotel == -1 ? true : false,
          //k3starhotel: visitor.data.k3starhotel == -1 ? true : false,
          //k4starhotel: visitor.data.k4starhotel == -1 ? true : false,
          palacehotels: visitor.data.palacehotels == -1 ? true : false,
          kvacation: visitor.data.kvacation == -1 ? true : false,
          kholidayclub: visitor.data.kholidayclub == -1 ? true : false,
          kbandb: visitor.data.kbandb == -1 ? true : false,
          krestaurant: visitor.data.krestaurant == -1 ? true : false,
          kincomingagency: visitor.data.kincomingagency == -1 ? true : false,
          kcongress: visitor.data.kcongress == -1 ? true : false,
          gsightseeing: visitor.data.gsightseeing == -1 ? true : false,
          kairtrain: visitor.data.kairtrain == -1 ? true : false,
          kcoach: visitor.data.kcoach == -1 ? true : false,
          kbarging: visitor.data.kbarging == -1 ? true : false,
          //kleisure: visitor.data.kleisure == -1 ? true : false,
          kmuseum: visitor.data.kmuseum == -1 ? true : false,
          //kshow: visitor.data.kshow == -1 ? true : false,
          kmall: visitor.data.kmall == -1 ? true : false,
          ktouristoffice: visitor.data.ktouristoffice == -1 ? true : false,
          danydietryallergies: visitor.data.danydietryallergies, // others
        });

        //console.log('------------------ config -----------');
        //console.log(environment);

        // build the dynamic checkboxes for each topic
        const identityKeys = this.getKeysIdentity();
        const programmingKeys = this.getKeysProgramming();
        const productsKeys = this.getKeysProducts();
        const regionsKeys = this.getKeysRegions();
        const productsReqKeys = this.getKeysProductsReq();

        // and now we aldo have to patch the values of the dynamic form fields
        // Patch identity group values
        const identityGroupValues = {};
        identityKeys.forEach((key) => {
          identityGroupValues[key] = +visitor.data[key] === -1 ? true : false;
        });
        (this.firstVisitorForm.get('compIdentityGroup') as FormGroup).patchValue(identityGroupValues);

        // Patch programming group values
        const programmingGroupValues = {};
        programmingKeys.forEach((key) => {
          programmingGroupValues[key] = +visitor.data[key] === -1 ? true : false;
        });
        (this.firstVisitorForm.get('programmingGroup') as FormGroup).patchValue(programmingGroupValues);

        // Patch products group values
        const productsGroupValues = {};
        productsKeys.forEach((key) => {
          productsGroupValues[key] = +visitor.data[key] === -1 ? true : false;
        });
        (this.firstVisitorForm.get('productsGroup') as FormGroup).patchValue(productsGroupValues);

        // Patch regions group values
        const regionsGroupValues = {};
        regionsKeys.forEach((key) => {
          regionsGroupValues[key] = +visitor.data[key] === -1 ? true : false;
        });
        (this.firstVisitorForm.get('soughtRegionsGroup') as FormGroup).patchValue(regionsGroupValues);

        // Patch productsreq group values
        const productsreqGroupValues = {};
        productsReqKeys.forEach((key) => {
          productsreqGroupValues[key] = +visitor.data[key] === -1 ? true : false;
        });
        (this.firstVisitorForm.get('productsRequested') as FormGroup).patchValue(productsreqGroupValues);
      });
    });
  }

  pondHandleInit() {
    console.log('FilePond has initialised', this.myPond);
  }

  pondHandleAddFile(event: any) {
    console.log('A file was added', event);

    // get the name of the upload object
    let elementId = event.pond.id.get();

    console.log(event.pond.files.get());

    // data-maxnumber should store the max numbers of file that are allowed to upload
    //return false;
  }

  pondHandleActivateFile(event: any) {
    console.log('A file was activated', event);
  }

  /**
   *
   * load the firststart Data that are already in the system
   */
  loadFirstStart() {}

  /**
   *  --------------- dynamic field keys
   *
   */
  getKeysIdentity() {
    return Object.keys(this.caption_dyn_identity);
  }
  getKeysProgramming() {
    return Object.keys(this.caption_dyn_programming);
  }

  getKeysProducts() {
    return Object.keys(this.caption_dyn_products);
  }
  getKeysRegions() {
    return Object.keys(this.caption_dyn_regions);
  }

  getKeysProductsReq() {
    return Object.keys(this.caption_dyn_productsreq);
  }

  /**
   *
   *
   */
  firstStartUpdate() {
    // tempory save -> firststart daten
    // bei step change
  }

  /**
   *
   *
   */
  submitFirststart() {
    console.log('submit');
    this.submitted = true;

    console.log('------- PRINT INVALID CONTROLS ---');
    for (let el in this.firstVisitorForm.controls) {
      if (this.firstVisitorForm.controls[el].errors) {
        console.log(el);
      }
    }

    // stop here if form is invalid
    // no validation for admin
    if (this._isAdmin == '1') {
      // no check
    } else {
      if (this.firstVisitorForm.invalid) {
        return;
        // we do not care if it is invalid
        // because we do a cache save
        //
      } else {
        // first-start can set to 1
        // and then save it agin
        //  (click)="submitFirststart()"
        this.firstVisitorForm.controls['firststart_done'].setValue(1);
      }
    }

    // display form values on success
    this.submitted = true;
    if (this.firstVisitorForm.valid) {
      // console.table(this.firstVisitorForm.value);
    }

    const access_token = this.authQuery.access_token;
    const userid = this._uid; //this.authQuery.userid;
    const fid = this._fid; //this.authQuery.fid;
    const role = this._role; //this.authQuery.role;

    //this.firstVisitorForm.controls['access_token'].setValue(access_token);
    this.firstVisitorForm.controls['uid'].setValue(userid);
    this.firstVisitorForm.controls['fid'].setValue(fid);
    this.firstVisitorForm.controls['role'].setValue(role);

    const firststart$ = this.authService.firststart(this.firstVisitorForm.value);
    firststart$
      .pipe(
        finalize(() => {
          this.firstVisitorForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        (data) => {
          if (data.status === 401) {
            console.log('login error');
            this._snackBar.open('Error: Something went wrong.', 'Close', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 3000,
              // panelClass: ['mat-toolbar', 'mat-primary'],
            });
          } else {
            log.debug(`${data.name} successfully firststart done`);

            this.journalService.log('FirstStart erfolgreich abgeschlossen.   --------------------------------------');

            // get firststart done
            let firststart_done = +data.firststart_done;

            // now route the user into the eplus system (sso )
            if (this._isAdmin == '1') {
              // admin just show that it was saved

              this._snackBar.open('Successfully saved.', 'Close', {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                duration: 3000,
                // panelClass: ['mat-toolbar', 'mat-primary'],
              });
            } else {
              // redirect to old system -belogin
              if (firststart_done == 1) {
                console.log('route to backend ---- sso');

                const myform = document.createElement('form');
                myform.method = 'POST';
                myform.action = environment.eplusSystem + '/belogin.php';
                myform.style.display = 'none';
                myform.append('Content-Type', 'application/x-www-form-urlencoded');
                myform.append('Accept', 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8');

                const inputAuth = document.createElement('input');
                inputAuth.type = 'hidden';
                inputAuth.name = 'Authorization';
                inputAuth.value = access_token;
                myform.appendChild(inputAuth);

                const inputUid = document.createElement('input');
                inputUid.type = 'hidden';
                inputUid.name = 'uid';
                inputUid.value = '' + userid;
                myform.appendChild(inputUid);

                const inputFid = document.createElement('input');
                inputFid.type = 'hidden';
                inputFid.name = 'fid';
                inputFid.value = '' + fid;
                myform.appendChild(inputFid);

                const inputRole = document.createElement('input');
                inputRole.type = 'hidden';
                inputRole.name = 'role';
                inputRole.value = '' + role;
                myform.appendChild(inputRole);

                document.body.appendChild(myform);
                myform.submit();
              } else {
                console.log('do nothing');
              }
            }
          }
        },
        (error) => {
          log.debug(`Login error: ${error}`);
          this.error = error;
        }
      );
  }

  getCountryName(country: string) {
    switch (country) {
      case 'at':
        return 'Austria';
      case 'ba':
        return 'Bosnia-Herzegovina';
      case 'bg':
        return 'Bulgaria';
      case 'hr':
        return 'Croatia';
      case 'cz':
        return 'Czech Republic';
      case 'fr':
        return 'France';
      case 'de':
        return 'Germany';
      case 'hu':
        return 'Hungary';
      case 'xk':
        return 'Kosovo';
      case 'mk':
        return 'Macedonia';
      case 'mne':
        return 'Montenegro';
      case 'pl':
        return 'Poland';
      case 'ro':
        return 'Romania';
      case 'xs':
        return 'Serbia';
      case 'sk':
        return 'Slovakia';
      case 'si':
        return 'Slovenia';
      case 'ch':
        return 'Switzerland';
      default:
        return '';
    }
  }
}
